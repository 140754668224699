<template lang="pug">
.space-page.flex.column.gap-20
  .search-bar.flex.align-center.justify-space-between
    .filter.flex.align-end.gap-10
      .column.flex.column.align-start.gap-4
        .title 預約時間
        el-date-picker.date-input(
          v-model="time")
      .column.flex.column.align-start.gap-4
        .title 館別
        place-selector.place-input(
          :clearable="false"
          :show-all="false"
          v-model="branch")
    el-button(
      type="primary", icon="el-icon-plus"
      @click="$refs.appointmentNew.$emit('show')"
      ) 新增預約
  appointment-room-view(
    ref="roomView"
    v-if="branch && time"
    :branch="branch", :time="time")
  appointment-new(
    ref="appointmentNew"
    @finish="reloadReserve"
  )
</template>

<script>
import { getBranches } from '@/api/place';
import AppointmentNew from '@/components/drawers/AppointmentNew.vue';
import AppointmentRoomView from '@/components/AppointmentRoomView.vue';

export default {
  components: {
    'appointment-new': AppointmentNew,
    'appointment-room-view': AppointmentRoomView,
  },
  data() {
    return {
      time: undefined,
      branch: undefined,
    };
  },
  computed: {
  },
  methods: {
    reloadReserve() {
      this.$refs.roomView.$emit('reload');
    },
    loadData() {
      this.$execWithLoading(async () => {
        const first = await this.loadBranch();
        this.branch = first.id;
      });
    },
    async loadBranch() {
      const branches = await getBranches(true);
      const branchMap = {};
      branches.forEach((b) => {
        branchMap[b.id] = b;
      });
      this.branchMap = branchMap;
      return branches[0];
    },
  },
  mounted() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    this.time = now;
    setTimeout(() => {
      this.loadData();
    }, 10);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.space-page {
  position: relative;
  padding: 30px 0;
  .search-bar {
    padding: 0 30px;
    .filter {
      .column {
        .title {
          font-size: 12px;
        }
        .date-input {
          width: 300px;
        }
        .place-input {
          width: 300px;
        }
      }
    }
  }
}
</style>
