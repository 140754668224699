<template lang="pug">
el-table(:data="activityList")
  el-table-column(width="30")
    template(slot-scope="scope")
      el-checkbox(v-model="scope.row.checked", :disabled="scope.row.confirm_status !== 'confirmed'")
  el-table-column(label="活動名稱")
    template(slot-scope="scope")
      .td-name.flex.align-center.justify-space-between
        .names.flex.align-center.wrap
          .name {{ scope.row.name }}
  el-table-column(label="專業人士", width="130")
    template(slot-scope="scope")
      pro-badge(
        :class="{'cancel-text': scope.row.isCancelled}"
        :professional="scope.row.professional")
  el-table-column(label="日期", width="120")
    template(slot-scope="scope")
      .td-time.flex.align-center
      span
        span {{ $timestampToDateWithDay(scope.row.start_at) }}
  el-table-column(label="鐘點總計", width="100")
    template(slot-scope="scope")
      span NT $ {{ scope.row.price }}
  el-table-column(label="分帳模組", width="150")
    template(slot-scope="scope")
      .flex.align-center.gap-4
        span {{ scope.row.accounting_model.name }}
        i.el-icon-edit.text-clickable(@click="$emit('edit', scope.row)")
  el-table-column(label="機構收益", width="100")
    template(slot-scope="scope")
      span NT $ {{ scope.row.organization_profit }}
  el-table-column(label="專業人士收益", width="120")
    template(slot-scope="scope")
      span NT $ {{ scope.row.professional_activity_payment }}
  el-table-column(label="入帳", width="100")
    template(slot-scope="scope")
      span(v-if="scope.row.payment_received_by === 'professional'") 專業人士
      span(v-else) 機構
  el-table-column(label="終點小結", width="120")
    template(slot-scope="scope")
      span NT $&nbsp;
      template
        span(v-if="scope.row.payment_received_by === 'professional'") {{ -1 * scope.row.organization_profit }}
        span(v-else) {{ scope.row.professional_activity_payment }}
  el-table-column(label="交通費", width="100")
    template(slot-scope="scope")
      .line.flex.column.gap-4
        span NT $ {{ scope.row.transportation_fare?.fare || 0 }}
        span(v-if="scope.row.transportation_fare?.received_by === 'professional'") (專業人士)
        span(v-if="scope.row.transportation_fare?.received_by === 'organization'") (機構)
        span(v-if="scope.row.transportation_fare?.received_by === 'paid_by_organization'") (機構自籌)
  el-table-column(label="雜費", width="100")
    template(slot-scope="scope")
      .line.flex.column.gap-4
        span NT $ {{ scope.row.incidental_expense?.expense || 0 }}
        span(v-if="scope.row.incidental_expense?.received_by === 'professional'") (專業人士)
        span(v-if="scope.row.incidental_expense?.received_by === 'organization'") (機構)
        span(v-if="scope.row.incidental_expense?.received_by === 'paid_by_organization'") (機構自籌)
  el-table-column(label="專業人士實收", width="120")
    template(slot-scope="scope")
      span NT $ {{ scope.row.professional_total_payment }}
  el-table-column(label="專業人士確認", width="120")
    template(slot-scope="scope")
      span.confirm-status(:class="scope.row.confirm_status") {{ $msg(`confirm_status.${scope.row.confirm_status}`) }}
  el-table-column(label="財管", width="180")
    template(slot-scope="scope")
      .accounting-month-cell.flex.align-center.gap-12
        template(v-if="scope.row.payslip")
          span {{ scope.row.monthStr }}
        template(v-else-if="scope.row.confirm_status === 'confirmed'")
          span {{ scope.row.monthStr }}
          el-date-picker.minimize-picker(
            type="month", v-model="scope.row.month", @change="changeAccountingMonth(scope.row, $event)")
        template(v-else)
          span 尚未選擇
</template>

<script>
import { setActivityAccountingMonth } from '@/api/accounting';
import { paddingToN } from '@/util/format';

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    hideColumn: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activityList: [],
      hideColumnMap: {},
    };
  },
  watch: {
    data() {
      this.setupList();
    },
  },
  methods: {
    setupList() {
      this.activityList = this.data.map((d) => ({
        ...d,
        month: undefined,
        monthStr: d.payslip ? `${d.payslip.year}-${paddingToN(d.payslip.month, 2)}` : '選擇結帳月份',
      }));
      this.hideColumn.forEach((col) => {
        this.hideColumnMap[col] = true;
      });
    },
    async changeAccountingMonth(activity, value) {
      return this.changeMultipleAccountingMonth([activity], value);
    },
    async changeMultipleAccountingMonth(activities, value) {
      return this.$execWithLoading(async () => {
        const month = `${value.getFullYear()}-${paddingToN(value.getMonth() + 1, 2)}`;
        await setActivityAccountingMonth(activities.map((a) => ({ id: a.id })), month);
        activities.forEach((activity) => {
        // eslint-disable-next-line no-param-reassign
          activity.monthStr = month;
          // eslint-disable-next-line no-param-reassign
          activity.month = undefined;
        });
        this.$forceUpdate();
      }, (e) => {
        this.$showAxiosException(e);
      });
    },
    getCheckedList() {
      return this.activityList.filter((r) => r.checked);
    },
  },
  mounted() {
    this.setupList();
    this.$on('select-all', () => {
      this.activityList = this.activityList.map((r) => ({
        ...r,
        checked: true,
      }));
    });
    this.$on('set-month', (data) => {
      this.changeMultipleAccountingMonth(this.getCheckedList(), data);
    });
  },
};
</script>

<style lang="scss" scoped>
.confirm-status {
    &.confirmed {
      color: #529B2E;
    }
    &.wrong_amount {
      color: #C45656;
    }
    &.not_notified {
      color: #C45656;
    }
}
</style>

<style lang="scss">
.accounting-month-cell {
  .minimize-picker {
    width: 35px;
    max-width: 35px;
    .el-input__inner {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
