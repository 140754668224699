<template lang="pug">
  user-viewer.user-viewer.show(
    @close="goCases"
    :uid="userID")
</template>

<script>
import UserViewer from '@/components/UserViewer.vue';

export default {
  props: {
    id: {
      type: String,
      require: true,
    },
  },
  computed: {
    userID() {
      return parseInt(this.id, 10);
    },
  },
  components: {
    'user-viewer': UserViewer,
  },
  methods: {
    goCases() {
      this.$router.push('/users');
    },
  },
};
</script>
