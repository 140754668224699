<template lang="pug">
.login-page.flex.align-stretch
  .left.flex.justify-center.align-center
    .logo.flex.justify-center.align-center
      img(src="/image/long-logo.png")
  .right.flex.align-center.justify-stretch
    .form.flex.column.align-stretch.gap-30
      .title 預約管理系統密碼重設
      .column.flex.column.gap-12
        .col-title 請輸入更新密碼
        el-input(type="password", v-model="password")
      el-button(
        type="primary", size="small", @click="updatePassword",
        :disabled="!canUpdatePassword") 登入
</template>

<script>
import { mapMutations } from 'vuex';
import {
  updatePasswordByToken,
} from '@/api/auth';

export default {
  name: 'Login',
  data() {
    return {
      token: '',
      password: '',
    };
  },
  computed: {
    canUpdatePassword() {
      return this.token !== '' && this.password !== '';
    },
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    async updatePassword() {
      this.$execWithLoading(async () => {
        await updatePasswordByToken(this.token, this.password);
        this.$showSuccess('更新密碼成功，請重新登入');
        this.$router.push({ name: 'Login' });
      }, () => {
        this.$showSuccess('已送出更新，請嘗試重新登入');
        this.$router.push({ name: 'Login' });
      });
    },
  },
  mounted() {
    this.setUserInfo(undefined);
    const queryString = window.location.hash.replace('#/forget-password', '');
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    this.token = urlParams.get('token');
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.login-page {
  .left {
    background: #C0E5E4;
    flex: 1;
    .logo {
      background: white;
      flex: 0 0 383px;
      height: 80px;
      border-radius: 70px;
      img {
        width: 269px;
      }
    }
  }
  .right {
    background: white;
    flex: 0 0 415px;
    .form {
      flex: 1;
      padding: 40px;
      text-align: left;

      .title {
        font-size: 24px;
      }

      .link {
        color: $primary-color;
        text-decoration: underline;
      }

      .seperator {
        height: 1px;
        background: #DCDFE6;
      }

      .validate-err {
        color: $danger-color;
      }
    }
  }
}
</style>

<style lang="scss">
.validator {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
