import SalaryPage from '../views/revenue/PaySlip.vue';
import AccountStatementPage from '../views/revenue/AccountStatementPage.vue';

export default [
  {
    path: 'account-statement',
    name: 'AccountStatement',
    title: '對帳紀錄',
    component: AccountStatementPage,
    showInMenu: true,
  },
  {
    path: 'salary',
    name: 'SettingSalary',
    title: '薪資明細',
    component: SalaryPage,
    showInMenu: true,
  },
];
