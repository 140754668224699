<template lang="pug">
el-drawer(
  title="新增館別"
  :visible.sync="drawer"
  direction="rtl"
  :before-close="handleClose")
  .drawer.flex.column.gap-20
    .data.flex.column.gap-6
      .title 館別名稱*
      .input.flex.align-center.justify-stretch
        el-input.input(v-model="name", placeholder="請輸入館別名稱")
    .data.flex.column.gap-6
      .title 空間名稱*
      .input.flex.align-center.justify-stretch.gap-10
        el-input.input(placeholder="請輸入空間名稱", v-model="newSpaceName")
        el-button(type="primary", icon="el-icon-plus",
          @click="addNewSpace", :disabled="newSpaceName === ''") 加入
    .spaces.flex.column.gap-20
      .space.flex.align-center.justify-stretch.gap-10(v-for="space in spaces")
        el-input(v-model="space.name")
        el-button(icon="el-icon-delete")
    .op.flex.align-center-justify-stretch.gap-10
      el-button.button(@click="cancel") 取消
      el-button.button(@click="addBranch", type="primary") 新增
</template>

<script>
import { addBranch } from '@/api/place';

export default {
  name: 'place-creator',
  data() {
    return {
      drawer: false,

      newSpaceName: '',

      name: '',
      spaces: [],
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    show() {
      this.drawer = true;
    },
    addNewSpace() {
      this.spaces.push({ name: this.newSpaceName, public: true });
      this.newSpaceName = '';
    },
    reset() {
      this.newSpaceName = '';
      this.spaces = [];
      this.name = '';
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
      this.reset();
    },
    cancel() {
      this.close(false);
    },
    async addBranch() {
      this.$execWithLoading(async () => {
        await addBranch(this.name, true, this.spaces);
        this.$showSuccess('設定更新');
        this.close(true);
      }, () => {
        this.$showError('設定失敗，請稍後重試');
      });
    },
  },
  mounted() {
    this.$on('show', () => {
      this.reset();
      this.show();
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 0 20px;
  padding-bottom: 20px;
  .op {
    .button {
      flex: 1;
    }
  }
}
</style>
