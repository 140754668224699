<template lang="pug">
.space-page.flex.column.align-start
  .op.flex.align-center
    el-button(
      @click="showAddNew"
      type="primary", size="large", icon="el-icon-plus") 新增單位
  .content.overflow-overlay.flex.column.align-start.gap-20
    .branch.flex.column.clickable(
      v-for="organization in sortedOrganization"
      :key="organization.id")
      .title.flex.align-center.gap-10(@click="goProjectPage(organization)")
        .text(:class="{'text-placeholder': !organization.public}") {{ organization.name }}
        toggle(
          v-if="showPublic"
          @input="changePublic(organization)"
          active-text="公開"
          inactive-text="隱藏"
          v-model="organization.public"
        )
        el-button(type="primary", size="mini", @click.native.stop="startEdit(organization)") 編輯
  organization-editor(ref="editor", @finish="finishEdit")
</template>

<script>
import {
  getOrganizations,
  updateOrganization,
} from '@/api/project';
import OrganizationEditor from '@/components/drawers/OrganizationEditor.vue';

export default {
  components: {
    'organization-editor': OrganizationEditor,
  },
  data() {
    return {
      organizations: [],
      showPublic: false,
    };
  },
  computed: {
    sortedOrganization() {
      const data = [...this.organizations];

      data.sort((a, b) => {
        if (a.public === b.public) {
          return a.name.localeCompare(b.name, 'zh-Hant');
        }
        if (!a.public) {
          return 1;
        }
        if (!b.public) {
          return -1;
        }
        return 1;
      });
      return data;
    },
  },
  methods: {
    goProjectPage(organization) {
      this.$router.push(`/setting/organization/${organization.id}`);
    },
    finishEdit(result) {
      if (result) {
        setTimeout(() => {
          this.loadData();
        }, 100);
      }
    },
    showAddNew() {
      this.$refs.editor.$emit('show');
    },
    async loadData() {
      return this.$execWithLoading(async () => {
        const data = await getOrganizations();
        if (data.length > 0 && data[0].public !== undefined) {
          this.showPublic = true;
        }
        this.organizations = data.map((b) => ({
          ...b,
          public: b.public === undefined ? true : b.public,
        }));
      }, () => {
        this.$showError('讀取館別失敗，請稍後重試');
      });
    },
    startEdit(data) {
      this.$refs.editor.$emit('show', data);
    },
    async changePublic(organization) {
      await this.$execWithLoading(async () => {
        await updateOrganization(organization.id, organization.name, organization.public);
        this.$showSuccess('更新成功');
        setTimeout(() => {
          this.loadData();
        });
      }, () => {
        this.$showError('更新失敗');
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.content {
  margin-left: -20px;
  padding: 20px;
}
.branch {
  width: 500px;
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
    0px 2px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  .title {
    flex: 0 0 64px;
    padding: 0 16px;
    .icon {
      flex: 0 0 auto;
    }
    .text {
      flex: 1;
    }
  }
  .rooms {
    border-top: 1px solid #CCC;
    padding: 20px;
    .empty-room {
      color: $text-placeholder-color;
    }
    .room {
      .disable {
        color: $text-placeholder-color;
      }
    }
  }
}
</style>
