<template lang="pug">
.reserve-page.flex.column.gap-20
  .search-bar.flex.align-center.justify-space-between
    .filter.flex.align-end.gap-10
      .column.flex.column.align-start.gap-4
        .title 預約時間
        el-date-picker.date-range-input(
          @change="changeDate"
          v-model="times"
          type="daterange"
          range-separator="至")
      .column.flex.column.align-start.gap-4
        .title 館別
        place-selector.place-input(v-model="branch", @input="changeBranch")
    el-button(
      type="primary", icon="el-icon-plus"
      @click="$refs.appointmentNew.$emit('show')"
      id="add-reserve-btn"
      ) 新增預約
  template(v-if="reserveList.length > 0")
    .table.flex
      el-table(:data="reserveList")
        el-table-column(label="個案姓名")
          template(slot="header", slot-scope="scope")
            .flex.align-center
              template(v-if="userSearch")
                user-picker(
                  size="mini"
                  @clear="setUserSearch(undefined)"
                  @input="setUserSearch")
              template(v-else)
                span 個案姓名
                i.el-icon-search.clickable(@click="userSearch = true")
          template(slot-scope="scope")
            .td-name.flex.align-center.justify-space-between
              .names.flex.align-center.wrap
                .name.clickable(
                  :class="{'text-placeholder': scope.row.isCancelled}"
                  @click="showCase(scope.row.user)"
                  ) {{ scope.row.user.family_name }}{{ scope.row.user.given_name }}
                template(v-if="scope.row.type !== 'parent'")
                  template(v-for="(p, idx) in scope.row.partners")
                    span(:key="`dot-${idx}`") 、
                    .name.clickable(
                      :key="p.id"
                      @click="showCase(p)"
                      ) {{ p.family_name }}{{ p.given_name }}
                template(v-else)
                  template(v-for="(p, idx) in scope.row.partners")
                    .name.clickable.ml-4(
                      :key="p.id"
                      @click="showCase(p)"
                      :class="{'cancel-text': scope.row.isCancelled}"
                      )
                      span (
                      template(v-if="scope.row.subtype === 'statutoryagents'")
                        span 法代
                        span :
                      template(v-else-if="scope.row.subtype === 'children'")
                        span 子女
                        span :
                      span {{ p.family_name }}{{ p.given_name }}
                      span )
              .note(v-if="scope.row.note")
                el-tooltip(:content="scope.row.note", placement="right")
                  i.el-icon-chat-dot-round.clickable
        el-table-column(label="標記", class-name="td-tags")
          template(slot-scope="scope")
            template(v-for="n in scope.row.tags")
              el-tag(v-if="scope.row.isCancelled" :key="n", type="info") {{ n }}
              el-tag(v-else :key="n") {{ n }}
            el-select.selector-no-text(
              v-if="scope.row.canEdit"
              multiple, v-model="scope.row.tags", @change="updateBranchTags(scope.row)")
              el-option(
                v-for="tag in tags",
                v-if="tag.public",
                :key="tag.name", :label="tag.name", :value="tag.name")
        el-table-column(label="預約時間與時長", width="210")
          template(slot-scope="scope")
            .td-time.flex.align-center
            span(:class="{'cancel-text': scope.row.isCancelled}")
              span {{ $timestampToDateTimeMinuteWithDay(scope.row.start_at) }}
            span(:class="{'cancel-text': scope.row.isCancelled}") &nbsp;({{ scope.row.length }} hr)
        el-table-column(label="預約型態", width="100")
          template(slot-scope="scope")
            span(
              :class="{'cancel-text': scope.row.isCancelled}"
              v-if="scope.row.location_type === 'inhouse'") 實體諮商
            span(
              :class="{'cancel-text': scope.row.isCancelled}"
              v-else) 遠距諮商
        el-table-column(label="預約空間", width="120")
          template(slot-scope="scope")
            span(:class="{'cancel-text': scope.row.isCancelled}") {{ scope.row.room.branch.name }}
            span(:class="{'cancel-text': scope.row.isCancelled}") &nbsp;{{ scope.row.room.name }}
        el-table-column(label="專業人士", width="130")
          template(slot="header", slot-scope="scope")
            .flex.align-center
              template(v-if="proSearch")
                pro-picker(
                  show-hide
                  size="mini"
                  ref="proPicker"
                  @clear="setProFilter(undefined)"
                  @input="setProFilter")
              template(v-else)
                span 專業人士
                i.el-icon-search.clickable(@click="editProSearch")
          template(slot-scope="scope")
            pro-badge(
              :class="{'cancel-text': scope.row.isCancelled}"
              :professional="scope.row.professional")
        el-table-column(label="預約價位", width="100")
          template(slot-scope="scope")
            span(:class="{'cancel-text': scope.row.isCancelled}") NT ${{ scope.row.price }}
        el-table-column(label="支付記錄", width="150")
          template(slot-scope="scope")
            template(v-if="scope.row.project")
              span(:class="{'cancel-text': scope.row.isCancelled}") {{ scope.row.projectName }}
            template(v-else-if="scope.row.isCancelled")
              .flex.align-center.gap-6
                span(:class="{'cancel-text-with-event': scope.row.isCancelled}")
                  template(v-if="scope.row.payment === '3rdparty'")
                    span 申請退款中
                    el-popover(trigger="hover", placement="top-start"
                      title="藍新支付ID", :content="scope.row.newebpay_trx_id")
                      i.el-icon-info(slot="reference")
                  template(v-else-if="scope.row.payment === '3rdparty_refunded'")
                    span 申請退款中
                    el-popover(trigger="hover", placement="top-start"
                      title="藍新支付ID", :content="scope.row.newebpay_trx_id")
                      i.el-icon-info(slot="reference")
                  template(v-else) ---
            template(v-else)
              .flex.align-center.gap-6
                span.danger-color(v-if="scope.row.payment === 'notpaid'") 尚未支付
                span(v-else-if="scope.row.payment === 'cash'") 現金支付
                span(v-else-if="scope.row.payment === '3rdparty'")
                  span 藍新金流
                  el-popover(trigger="hover", placement="top-start"
                    title="藍新支付ID", :content="scope.row.newebpay_trx_id")
                    i.el-icon-info(slot="reference")
                span(v-else-if="scope.row.payment === 'creditcard'") 信用卡支付
                span(v-else-if="scope.row.payment === 'transfer'") 轉帳支付
                el-dropdown(
                  v-if="(scope.row.canChangePaid || isAdmin) && paidCanChange(scope.row.payment)"
                  @command="updatePayment(scope.row, $event)", trigger="click")
                  el-button.dropdown-button(icon="el-icon-arrow-down", size="mini")
                  el-dropdown-menu(slot="dropdown")
                    el-dropdown-item(command='notpaid') 尚未付款
                    el-dropdown-item(command='cash') 現金支付
                    el-dropdown-item(command='transfer') 轉帳支付
                    el-dropdown-item(command='creditcard') 信用卡
        el-table-column(label="個案記錄", width="100")
          template(slot-scope="scope")
            span.cancel-text(v-if="scope.row.isCancelled") 個案已取消
            span(v-else-if="scope.row.has_record && scope.row.record?.status === 'uploaded'")
              template(v-if="canDownloadResult")
                .link-text.text-clickable.flex.align-center.gap-4(@click="tryDownload(scope.row.record.url)")
                  img(:src="successLogo")
                  span 下載紀錄
              template(v-else)
                img(:src="successLogo")
            span.danger-color(v-else) 尚未完成
        el-table-column(label="支援報備")
          template(slot-scope="scope")
            template(v-if="scope.row.need_note")
              span(v-if="scope.row.noted") 已報備
              span(v-else) 尚未報備
            template(v-else)
              span --
        el-table-column(label="操作", width="60")
          template(slot-scope="scope")
            el-dropdown(
              :disabled="scope.row.isCancelled"
              @command="execCommand($event, scope.row)"
            )
              el-button.dropdown-button(icon="el-icon-arrow-down", size="mini")
              el-dropdown-menu(slot="dropdown")
                el-dropdown-item(command="send") 傳送訊息給此個案
                el-dropdown-item(command="edit"
                  :disabled="!scope.row.canEdit && !isAdmin") 編輯預約
                //- el-dropdown-item 下載此筆付款收據
                el-dropdown-item(
                  :disabled="scope.row.location_type === 'inhouse'", command="copyLink") 複製諮商連結
                el-dropdown-item(command="cancel"
                  :disabled="!scope.row.canCancel && !isAdmin") 取消此預約記錄
    .pagination.flex.align-center.justify-end
      el-pagination(
        layout="total, sizes, prev, pager, next, ->, jumper"
        :page-size.sync="limit"
        :current-page.sync="currentPage"
        :total="total"
      )
  .empty(v-else) 此篩選無任何預約記錄
  person-viewer(
    ref="personViewer"
    @showDetail="showDetail"
  )
  appointment-new(
    ref="appointmentNew"
    @finish="loadData"
  )
  appointment-edit(
    ref="appointmentEdit"
    @finish="loadData"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import { getTags } from '@/api/tags';
import {
  getAppointments,
  updateAppointmentTags,
  updatePayment,
  cancelAppointment,
  setAppointmentNoted,
} from '@/api/reserve';
import {
  getUser,
} from '@/api/user';
import { getOrganizations } from '@/api/project';
import successLogo from '@/assets/logo/success.svg';
import PersonViewer from '@/components/drawers/PersonViewer.vue';
import AppointmentNew from '@/components/drawers/AppointmentNew.vue';
import AppointmentEditor from '@/components/drawers/AppointmentEdit.vue';
import constant from '@/util/constant';

export default {
  components: {
    PersonViewer,
    'appointment-edit': AppointmentEditor,
    'appointment-new': AppointmentNew,
  },
  data() {
    return {
      times: [],
      branch: undefined,
      reserveList: [],
      tags: [],

      userFilter: undefined,
      proFilter: undefined,
      userSearch: false,
      proSearch: false,

      total: 10,
      currentPage: 1,
      limit: 100,
      filter: {},

      successLogo,
      detailUser: undefined,
      projectOrgMap: {},
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'isAdvStaff']),
    canDownloadResult() {
      return this.isAdmin || this.isAdvStaff;
    },
  },
  watch: {
    currentPage() {
      this.loadData();
    },
    limit() {
      this.loadData();
    },
  },
  methods: {
    checkNoted(data, ev) {
      ev.stopPropagation();
      ev.preventDefault();
      console.log(data);
      this.$execWithLoading(async () => {
        await setAppointmentNoted(data.id, !data.noted);
        this.$showSuccess(!data.noted ? '已設定完成報備' : '已取消完成報備');
        // eslint-disable-next-line no-param-reassign
        data.noted = !data.noted;
      }, (e) => {
        console.log(e);
        this.$showAxiosException('設定報備狀態失敗', e);
      });
    },
    tryDownload(url) {
      this.$goLink(url);
      // try download will met CORS problem
      // this.$execWithLoading(async () => {
      //   await axios.get(url);
      //   this.$goLink(url);
      // }, () => {
      //   this.$showError('檔案尚未準備完成，請稍候下載');
      // });
    },
    editProSearch() {
      this.proSearch = true;
      this.$nextTick(() => {
        this.$refs.proPicker.$emit('focus');
      });
    },
    execCommand(cmd, appointment) {
      console.log({ cmd, appointment });

      if (cmd === 'edit') {
        this.$refs.appointmentEdit.$emit('show', appointment);
      } else if (cmd === 'cancel') {
        this.cancelAppointment(appointment);
      } else if (cmd === 'send') {
        this.tryOpenChat(appointment.user.id);
      } else if (cmd === 'copyLink') {
        this.$copy(appointment.meeting_link, true);
      }
    },
    async tryOpenChat(userID) {
      return this.$execWithLoading(async () => {
        const data = await getUser(userID);
        if (data.line_chat_id && data.line_chat_id !== '') {
          this.$goLink(`${constant.lineChatURL}/${data.line_chat_id}`);
        } else {
          this.$showError('該使用者尚未設定 LINE 聊天 ID');
        }
      });
    },
    async cancelAppointment(appointment) {
      this.$confirm('確定要取消此預約嗎').then(
        () => this.$execWithLoading(async () => {
          await cancelAppointment(appointment.id);
          this.$showSuccess('已取消預約');
          this.loadData();
        }),
      ).catch(() => {});
    },
    showDetail(user) {
      this.$root.$emit('show-user', user);
    },
    paidCanChange(payment) {
      return ['notpaid', 'cash', 'transfer', 'creditcard'].indexOf(payment) >= 0;
    },
    showCase(data) {
      this.$refs.personViewer.$emit('show', {
        type: 'id',
        data: data.id || data.user.id,
      });
    },
    getFilter() {
      if (!this.times || this.times.length === 0) {
        this.times = this.getDefaultTime();
      }
      const [start, end] = this.times;
      end.setHours(23, 59, 59, 999);
      const filter = {
        start_at: parseInt(start.getTime() / 1000, 10),
        end_at: parseInt(end.getTime() / 1000, 10),
        branch: (this.branch !== '' && this.branch) ? this.branch : undefined,
        page: this.currentPage - 1,
        limit: this.limit,
      };
      if (this.userFilter) {
        filter.user = this.userFilter;
      }
      if (this.proFilter) {
        filter.professional = this.proFilter;
      }
      return filter;
    },
    changeDate() {
      this.page = 1;
      this.$execWithLoading(async () => {
        await this.loadReserveList();
      });
    },
    loadData() {
      this.$execWithLoading(async () => {
        this.reserveList = [];
        const rsps = await Promise.all([
          this.getOrganizations(),
          this.loadTags(),
          this.loadReserveListFromAPI(),
        ]);
        this.parseAPIReserves(rsps[2]);
      });
    },
    async getOrganizations() {
      const rsp = await getOrganizations();
      this.projectOrgMap = {};
      rsp.forEach((org) => {
        org.projects.forEach((p) => {
          this.projectOrgMap[p.id] = org;
        });
        // eslint-disable-next-line no-param-reassign
        org.projects = org.projects.filter((p) => p.public);
      });
    },
    async loadReserveListFromAPI() {
      return getAppointments(this.getFilter());
    },
    parseAPIReserves(reserve) {
      let reserveList = reserve;
      const tagMap = this.tags.reduce((ret, t) => {
        // eslint-disable-next-line no-param-reassign
        ret[t.name] = t.public;
        return ret;
      }, {});
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayTs = parseInt(today.getTime() / 1000, 10);

      const now = new Date();
      const nowTs = parseInt(now.getTime() / 1000, 10);

      const { meta } = reserveList;
      if (reserveList.appointments) {
        reserveList = reserveList.appointments;
        this.total = meta.total || 0;
      }
      this.reserveList = reserveList.map((r) => ({
        ...r,
        tags: r.tags.filter((t) => tagMap[t]),
        length: ((r.end_at - r.start_at) / 3600).toFixed(1),
        isCancelled: r.status === 'cancelled',
        type: r.appointment_type || r.type || '',
        subtype: r.appointment_subtype || r.subtype || 'statutoryagents',
        projectName: r.project ? this.projectOrgMap[r.project.id].name : '',
        canEdit: r.start_at >= todayTs,
        canCancel: nowTs < r.end_at + 86400,
        canChangePaid: nowTs < r.end_at + 86400,
        need_note: r.professional.branches.findIndex((v) => v.id === r.room.branch.id) < 0,
      }));
    },
    async loadReserveList() {
      this.reserveList = [];
      const reserves = await this.loadReserveListFromAPI();
      this.parseAPIReserves(reserves);
    },
    async loadTags() {
      const tags = await getTags();
      this.tags = tags;
    },
    async updateBranchTags(branch) {
      return this.$execWithLoading(async () => {
        await updateAppointmentTags(branch.id, branch.tags);
        this.$showSuccess('已更新標記');
      }, () => {
        this.$showError('更新標記失敗');
        this.loadData();
      });
    },
    changeBranch() {
      this.loadData();
    },
    setUserSearch(u) {
      if (u === undefined) {
        this.userSearch = false;
        this.userFilter = undefined;
      } else {
        // this.userFilter = `${u.family_name}${u.given_name}`;
        this.userFilter = `${u.given_name}`;
        // this.userFilter = u.id;
      }
      this.currentPage = 1;
      this.loadData();
    },
    setProFilter(p) {
      if (p === undefined) {
        this.proSearch = false;
        this.proFilter = undefined;
      } else {
        // this.proFilter = `${p.family_name}${p.given_name}`;
        this.proFilter = `${p.given_name}`;
        // this.proFilter = p.id;
      }
      this.currentPage = 1;
      this.loadData();
    },
    updatePayment(appointment, method) {
      this.$execWithLoading(async () => {
        await updatePayment(appointment.id, method);
        // eslint-disable-next-line no-param-reassign
        appointment.payment = method;
        this.$showSuccess('更新成功');
      }, () => {
        this.$showError('更新失敗，請稍後再試');
      });
    },
    changeSearchUser() {
      this.userFilter = undefined;
    },
    getDefaultTime() {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const start = new Date(now.getTime());
      const end = new Date(now.getTime() + 7 * 86400 * 1000);
      end.setHours(0, 0, 0, 0);
      return [start, end];
    },
  },
  mounted() {
    this.times = this.getDefaultTime();
    setTimeout(() => {
      this.loadData();
    }, 10);
  },
};
</script>

<style lang="scss" scoped>
.reserve-page {
  position: relative;
  padding: 30px 0;
  .search-bar {
    padding: 0 30px;
    .filter {
      .column {
        .title {
          font-size: 12px;
        }
        .date-range-input {
          width: 440px;
        }
        .place-input {
          width: 300px;
        }
      }
    }
  }
  .table {
    flex: 1;
    .record-link {
      text-decoration: none;
    }
  }
  .pagination {
    flex: 0 0 auto;
  }
  .empty {
    padding: 0 30px;
    margin-top: 20px;
  }
}
</style>

<style lang="scss">
@import '@/assets/style/variables.scss';

.reserve-page {
  & > .table {
    margin: 0 30px;
    overflow: hidden;
    .td-tags {
      .cell {
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
      }
    }
    .td-name {
      color: $primary-color;
    }
  }

  // hack
  .el-table {
    border: 1px solid #EBEEF5;
    border-bottom: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    .el-table__header {
      thead {
        tr {
          th {
            background: #E6F4F4;
            border-left: 1px solid #EBEEF5;
          }
        }
      }
    }
    .el-table__body {
      tbody {
        tr {
          td {
            border-left: 1px solid #EBEEF5;
          }
        }
      }
    }

    // handle scroll
    .el-table__header-wrapper {
      flex: 0 0 auto;
    }
    .el-table__body-wrapper {
      flex: 1;
      overflow: overlay;
    }
  }
  .dropdown-button {
    padding: 7px;
  }
  .cancel-text {
    pointer-events: none;
    color: $text-placeholder-color;
  }
  .cancel-text-with-event {
    color: $text-placeholder-color;
  }
}
</style>
