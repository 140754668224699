<template lang="pug">
.accounting-page.flex.column.align-start.gap-20
  .op.flex.align-center.gap-20
    el-button(
      @click="showAddNew"
      type="primary", size="large", icon="el-icon-plus") 新增分帳模組
    .desc 建立心理醫療機構與專業人士間每筆會診收入的分帳模組
  .table.hack-table
    el-table(:data="accountings", width="600")
      el-table-column(label="標籤名稱" width="190")
        template(slot-scope="scope")
          .flex.column.align-start
            template(v-if="scope.row.public")
              .active {{ scope.row.name }}
              .date.text-secondary 新增日期 {{ $timestampToDate(scope.row.created_at) }}
            template(v-else)
              .inactive {{ scope.row.name }}
              .date.inactive 新增日期 {{ $timestampToDate(scope.row.created_at) }}
      el-table-column(label="類別" width="90")
        template(slot-scope="scope")
          .flex.align-center(:class="{'inactive': !scope.row.public}")
            span(v-if="scope.row.model_type === 'appointment'") 預約
            span(v-else) 活動
      el-table-column(label="分配" width="200")
        template(slot-scope="scope")
          .flex.align-center(:class="{'inactive': !scope.row.public}")
            .ratio.flex.align-center(v-if="scope.row.type === 'ratio'")
              span 比例分配 {{ scope.row.ratio1st }}:{{ scope.row.ratio2nd }}
            .fixedprice(v-else) 定額分配 NT$ {{ scope.row.organization_fixedprice }}
      el-table-column(label="清單" width="90")
        template(slot-scope="scope")
          toggle(
            @input="updatePublic(scope.row, $event)"
            active-text="公開"
            inactive-text="隱藏"
            v-model="scope.row.public")
      el-table-column(label="操作" width="150")
          template(slot-scope="scope")
            .ops.flex.align-center
              el-button(size="mini", @click="startEdit(scope.$index)") 編輯
  el-dialog(
    custom-class="no-close"
    width="540px"
    title="新增分帳模組名稱"
    :visible.sync="newVisible"
    :show-close="false")
    .flex.column.align-stretch.gap-20
      .col.flex.column.align-stretch.gap-4
        .title 分帳模組名稱
        el-input(v-model.trim="name")
      .col.flex.column.gap-4
        .title 類別
        el-select(v-model="accountClass" :disabled="editing")
          el-option(label="預約", value="appointment")
          el-option(label="活動", value="activity")
      .col.flex.column.gap-4
        .title 模組種類
        el-radio-group.flex.align-center.gap-10(v-model="accountingType")
          el-radio(:disabled="editing" label="ratio" border) 比例固定
          el-radio(:disabled="editing" label="fixedprice" border) 定額分配
      .detail.flex.column.gap-10
        template(v-if="accountingType === 'ratio'")
          .title.font-size-14 比例分配 (請輸入 0~100)
          .values.flex.align-end.gap-4
            .left.flex.column.gap-4
              .title 機構
              el-input(
                :disabled="editing"
                placeholder='請輸入機構比例', v-model="ratio1", type="number", max="100", min="0")
            .middle :
            .right.flex.column.gap-4
              .title 專業人士
              el-input(
                :disabled="editing"
                placeholder='請輸入專業人士比例', v-model="ratio2", type="number", max="100", min="0")
        template(v-else="accountingType === 'ratio'")
          .title.font-size-14 定額價位 (請輸入機構固定金額)
          .values.flex.align-end.gap-4
            .left.flex.column.gap-4
              .title(v-if="accountClass === 'appointment'") 機構
              .title(v-else) 專業人士
              .input-row.flex.align-center
                el-input(
                  :disabled="editing"
                  placeholder='請輸入機構金額', v-model="fixPrice", type="number")
                .text(v-if="isHourlyFee") &nbsp;/ 小時
          .values.flex.align-end.gap-4
            .left.flex.column.gap-8
              el-checkbox(v-model="isHourlyFee") 根據「預約時長」進行比例計算
              el-checkbox(
                v-if="accountClass === 'appointment'", v-model="commision") 額外加收「預約價位」10% 的金額
        .splitter
        .desc 以每筆諮商收入 NT$ 3000 為範例，以下是機構與專業人士的收入分配
        .values.flex.align-end.gap-4
          .left.flex.column.gap-4
            .title 機構
            el-input(disabled, placeholder='---', type="number", v-model="estimate1")
          .middle :
          .right.flex.column.gap-4
            .title 專業人士
            el-input(disabled, placeholder='---', type="number", v-model="estimate2")
    .footer(slot="footer")
      el-button(@click="close") 取消
      template(v-if="editing")
        el-button(type="primary", @click="saveEdit", :disabled="!canAdd") 確定
      template(v-else)
        el-button(type="primary", @click="addNewAccounting", :disabled="!canAdd") 確定
</template>

<script>
import {
  getAccountings,
  updateAccounting,
  createAccounting,
} from '@/api/accounting';

export default {
  data() {
    return {
      newVisible: false,
      accountings: [],

      name: '',
      accountClass: 'appointment',
      accountingType: 'ratio',
      ratio1: '',
      ratio2: '',
      fixPrice: '',
      estimate1: undefined,
      estimate2: undefined,
      isHourlyFee: false,
      commision: false,

      editIdx: undefined,
    };
  },
  watch: {
    ratio1() {
      if (this.ratio1 === '') {
        this.ratio2 = '';
        return;
      }
      let num = parseInt(this.ratio1, 10);
      if (Number.isNaN(num)) {
        num = '';
      }
      if (num > 100) {
        num = 100;
      }
      if (num < 0) {
        num = 0;
      }
      this.ratio1 = num;
      this.ratio2 = 100 - num;
      this.calculateEstimate();
    },
    ratio2() {
      if (this.ratio2 === '') {
        this.ratio1 = '';
        return;
      }
      let num = parseInt(this.ratio2, 10);
      if (Number.isNaN(num)) {
        num = '';
      }
      if (num > 100) {
        num = 100;
      }
      if (num < 0) {
        num = 0;
      }
      this.ratio2 = num;
      this.ratio1 = 100 - num;
      this.calculateEstimate();
    },
    fixPrice() {
      if (this.fixPrice < 0) {
        this.fixPrice = 0;
      } else if (this.fixPrice > 3000) {
        this.fixPrice = 3000;
      }
      this.fixPrice = parseInt(this.fixPrice, 10);
      this.calculateEstimate();
    },
    accountingType() {
      this.calculateEstimate();
    },
    isHourlyFee() {
      this.calculateEstimate();
    },
    commision() {
      this.calculateEstimate();
    },
    accountClass() {
      this.calculateEstimate();
    },
  },
  computed: {
    editing() {
      return this.editIdx !== undefined;
    },
    canAdd() {
      if (this.name === '') {
        return false;
      }
      if (this.accountingType === 'ratio') {
        if (this.ratio1 === undefined || this.ratio1 === '') {
          return false;
        }
      }
      if (this.accountingType === 'fixedprice') {
        if (this.fixPrice === undefined || this.fixPrice === '') {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    showAddNew() {
      this.reset();
      this.newVisible = true;
    },
    startEdit(idx) {
      this.editIdx = idx;
      const data = this.accountings[idx];
      this.name = data.name;
      this.accountingType = data.type;
      this.accountClass = data.model_type;
      if (this.accountingType === 'ratio') {
        this.ratio1 = (data.organization_ratio * 100).toFixed(0);
        this.ratio2 = 100 - this.ratio1;
      } else {
        this.fixPrice = data.organization_fixedprice;
      }

      this.calculateEstimate();
      this.newVisible = true;
    },
    cancelEdit(idx) {
      const data = this.accountings[idx];
      data.editMode = false;
      data.editValue = data.name;
      this.$forceUpdate();
    },
    saveEdit() {
      const data = this.accountings[this.editIdx];
      this.$execWithLoading(async () => {
        await updateAccounting(data.id, this.name, data.public);
        data.name = this.name;
        data.editIdx = undefined;
        this.newVisible = false;
        this.$showSuccess('儲存成功');
      });
    },
    calculateEstimate() {
      if (this.accountingType === 'ratio') {
        const num = parseInt(this.ratio1, 10);
        if (Number.isNaN(num)) {
          this.estimate1 = undefined;
          this.estimate2 = undefined;
          return;
        }

        this.estimate1 = (3000 * (this.ratio1 / 100)).toFixed(0);
        this.estimate2 = (3000 * (this.ratio2 / 100)).toFixed(0);
        return;
      }
      let num = parseInt(this.fixPrice, 10);
      if (Number.isNaN(num)) {
        this.estimate1 = undefined;
        this.estimate2 = undefined;
        return;
      }
      if (this.accountClass !== 'appointment') {
        this.commision = false;
      }
      num *= (this.isHourlyFee ? 1.5 : 1);
      num *= (this.commision ? 1.1 : 1);
      num = parseInt(num.toFixed(0), 10);
      if (this.accountClass !== 'appointment') {
        num = 3000 - num;
      }

      this.estimate1 = num;
      this.estimate2 = 3000 - this.estimate1;
    },
    reset() {
      this.name = '';
      this.accountingType = 'ratio';
      this.ratio1 = '';
      this.ratio2 = '';
      this.fixPrice = '';
      this.estimate1 = undefined;
      this.estimate2 = undefined;
      this.editIdx = undefined;
      this.accountClass = 'appointment';
      this.isHourlyFee = false;
      this.commision = false;
    },
    close() {
      this.newVisible = false;
      this.reset();
    },
    async loadData() {
      this.$execWithLoading(async () => {
        const data = await getAccountings();
        data.sort((a, b) => {
          if (!a.public) {
            return 1;
          }
          if (!b.public) {
            return -1;
          }
          return 1;
        });
        this.accountings = data.map((d) => ({
          ...d,
          editValue: d.name,
          ratio1st: (d.organization_ratio * 100).toFixed(0),
          ratio2nd: (100 - d.organization_ratio * 100).toFixed(0),
          editMode: false,
        }));
      });
    },
    async updatePublic(data, val) {
      this.$execWithLoading(async () => {
        await updateAccounting(data.id, data.name, val);
      });
    },
    async addNewAccounting() {
      this.$execWithLoading(async () => {
        const data = {
          name: this.name,
          public: true,
          type: this.accountingType,
          model_type: this.accountClass,
        };
        if (this.accountingType === 'ratio') {
          data.organization_ratio = parseFloat((this.ratio1 / 100).toFixed(2));
        } else {
          data.organization_fixedprice = this.fixPrice;
          data.is_hourly_fee = this.isHourlyFee;
          data.commision = this.commision;
        }
        await createAccounting(data);
        this.$showSuccess('新增成功');
        this.newVisible = false;
        this.loadData();
      }, (e) => {
        this.$showAxiosException(e);
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.table {
  overflow: hidden;
  .inactive {
    color: $text-placeholder-color;
  }
}

.el-radio {
  margin: 0;
}
.detail {
  background: #F4F4F5;
  border-radius: 4px;
  padding: 10px;
}
.values {
  .middle {
    line-height: 32px;
  }
  .el-input {
    width: 160px;
  }
}

</style>
