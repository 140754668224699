import instance from './base';

export async function getAccounts() {
  const rsp = await instance.get('/v1/accounts');
  return rsp.data;
}

export async function setAccountEnable(id, enabled) {
  const rsp = await instance.patch(`/v1/accounts/${id}/enabled`, { enabled });
  return rsp.data;
}

export async function deleteAccountToken(id) {
  const rsp = await instance.delete(`/v1/accounts/${id}/token`);
  return rsp.data;
}

export async function deleteProfessionalToken(id) {
  const rsp = await instance.delete(`/v1/professionals/${id}/token`);
  return rsp.data;
}

export async function newAccount(name, email, phone, role, branches) {
  const rsp = await instance.post('/v1/accounts', {
    family_name: name[0],
    given_name: name.substr(1),
    phone,
    email,
    role,
    enabled: true,
    branches,
  });
  return rsp.data;
}

export async function updateAccount(id, name, email, phone, role, enabled, branches) {
  const rsp = await instance.put(`/v1/accounts/${id}`, {
    family_name: name[0],
    given_name: name.substr(1),
    phone,
    email,
    role,
    enabled,
    branches,
  });
  return rsp.data;
}
