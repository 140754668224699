<template lang="pug">
el-drawer(
  title="編輯蛹之生人員"
  :visible.sync="drawer"
  :close-on-press-escape="false"
  :wrapperClosable="false"
  direction="rtl"
  :modal-append-to-body="false"
  :before-close="handleClose")
  .drawer.flex.column.gap-20
    template(v-if="!editPassword")
      .data.flex.column.align-stretch.gap-6
        .title 姓名*
        el-input(v-model.trim="name", placeholder="請輸入姓名")
      //- .data.flex.column.align-stretch.gap-6
      //-   .title Email*
      //-   el-input(
      //-     v-model.trim="email", placeholder="請輸入 Email")
      .data.flex.column.align-stretch.gap-6
        .title 角色
        el-select(v-model="role" disabled)
          el-option(value="admin", label="管理員")
          el-option(value="staff", label="個管師")
          el-option(value="advstaff", label="進階個管師")
      .data.flex.column.align-start.gap-6
        .title 重設密碼
        el-button(type="primary", @click="openPasswordEditor") 變更
    template(v-else)
      .data.flex.column.align-stretch.gap-6
        .title 舊的密碼
        el-input(v-model="oldPassword", type="password", placeholder="請輸入原本的密碼")
      .data.flex.column.align-stretch.gap-6
        .title 新的密碼
        el-input(v-model="newPassword", type="password", placeholder="請輸入新的密碼")
      .data.flex.column.align-stretch.gap-6
        .title 再次輸入新的密碼
        el-input(v-model="confirmPassword", type="password", placeholder="請再次輸入新的密碼")
    .op.flex.align-center-justify-stretch.gap-10
      el-button.button(@click="cancel") 取消
      el-button.button(@click="save", type="primary", :disabled="!valid") 更新
</template>

<script>
import { mapGetters } from 'vuex';
import {
  updateSelfName,
  updateSelfPassword,
} from '@/api/auth';

export default {
  name: 'self-password-editor',
  data() {
    return {
      origData: undefined,
      drawer: false,

      name: '',
      email: '',
      country: '+886',
      phone: '',
      role: '',
      branch: undefined,
      editPassword: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
  },
  computed: {
    ...mapGetters(['user']),
    userData() {
      return this.user || {};
    },
    valid() {
      if (this.name === '') {
        return false;
      }
      return true;
    },
  },
  watch: {
    userData() {
      this.name = `${this.userData.family_name}${this.userData.given_name}`;
    },
  },
  methods: {
    openPasswordEditor() {
      this.editPassword = true;
    },
    handleClose(done) {
      done();
    },
    show() {
      this.drawer = true;
    },
    close(result) {
      this.reset();
      this.drawer = false;
      this.$emit('finish', result);
    },
    reset() {
      this.origData = undefined;
      this.name = '';
      this.email = '';
      this.role = '';
      this.country = '+886';
      this.phone = '';
      this.branch = [];
      this.resetPassword();
    },
    resetPassword() {
      this.oldPassword = '';
      this.newPassword = '';
      this.confirmPassword = '';
    },
    cancel() {
      if (this.editPassword) {
        this.resetPassword();
        this.editPassword = false;
        return;
      }
      this.close(false);
    },
    save() {
      if (this.editPassword) {
        this.savePassword();
        return;
      }
      this.$execWithLoading(async () => {
        await updateSelfName(
          this.name,
        );
        this.$showSuccess('更新成功');
        this.close(true);
      }, (e) => {
        this.$showAxiosException('更新帳號資訊', e);
      });
    },
    savePassword() {
      return this.$execWithLoading(async () => {
        await updateSelfPassword(
          this.oldPassword,
          this.newPassword,
        );
        this.$showSuccess('更新密碼成功');
        this.resetPassword();
        this.editPassword = false;
      }, (e) => {
        this.$showAxiosException('更新密碼', e);
      });
    },
  },
  mounted() {
    this.$on('show', () => {
      this.name = `${this.userData.family_name}${this.userData.given_name}`;
      this.role = this.userData.role || 'admin';
      this.show();
    });
  },
};
</script>

  <style lang="scss" scoped>
  @import '@/assets/style/variables.scss';

  .drawer {
    padding: 20px;
    .button {
      flex: 1;
    }
    .data {
      .left {
        flex: 0 0 120px;
      }
      .right {
        flex: 1;
      }
    }
  }
  </style>
