<template lang="pug">
.login-page.flex.align-stretch
  .left.flex.justify-center.align-center
    .logo.flex.justify-center.align-center
      img(src="/image/long-logo.png")
  .right.flex.align-center.justify-stretch
    .form.flex.column.align-stretch.gap-30(v-if="step === 'login'")
      .title 登入預約管理系統
      .column.flex.column.gap-12
        .col-title Email 帳號
        el-input(v-model="email" name="email")
      .column.flex.column.gap-12
        .col-title 密碼
        el-input(type="password", v-model="password")
      el-button(
        type="primary", size="small", @click="doLogin", :disabled="!canLogin", id='login-btn') 登入
      .link.clickable(@click="goStep('forget')") 忘記密碼?

    .form.flex.column.align-stretch.gap-30(v-if="step === 'binding'")
      .title 設定兩步驟驗證
      .desc.flex.column.gap-12
        .line.flex.gap-6
          span 請安裝
          .link.clickable Google Authenticator
          span 來完成驗證
        .line 使用你的 APP 掃描 QR code
        .line
          qr-code(
            :text="otpURL"
            :size="105"
            error-level="L")
      .seperator
      .column.flex.column.gap-12.validator
        .col-title 輸入 APP 生成的六位數驗證至下方
        el-input(type="number", v-model="validator", :maxlength="6", name="totp-bind")
        .validate-err.danger-color(v-if="hasValidatorErr") 驗證碼不正確
      el-button(type="primary", size="small", :disabled="!canBind", @click="doBinding") 進行設定

    .form.flex.column.align-stretch.gap-30(v-if="step === 'binded'")
      .title 設定兩步驟驗證
      .desc.success-color 設定驗證成功，請重新登入帳號密碼
      el-button(type="primary", size="small", @click="goStep('login')") 回到登入頁面

    .form.flex.column.align-stretch.gap-30(v-if="step === 'second-factor'")
      .title 兩步驟驗證
      .column.flex.column.gap-12.validator
        .col-title 請輸入 Google Authenticator 中的驗證碼
        el-input(type="number", v-model="validator", :maxlength="6",  name="totp-verify")
        .validate-err.danger-color(v-if="hasValidatorErr") 驗證碼不正確
      el-button(
        type="primary", size="small", :disabled="!canBind",
        @click="doValidate", id='verify-btn') 進行驗證
    .form.flex.column.align-stretch.gap-30(v-if="step === 'forget'")
      .title 無法登入嗎?
      .desc 我們將會寄 Email 讓您重新設定密碼
      .column.flex.column.gap-12.validator
        .col-title 請輸入您的 Email 帳號
        el-input(v-model="email", name="email")
      el-button(type="primary", size="small", :disabled="email === ''", @click="doForget") 重新設定密碼

    .form.flex.column.align-stretch.gap-30(v-if="step === 'forget-send'")
      .title 重新設定密碼的信件已寄出
      .desc 如果我們有您剛剛輸入的電子郵件的帳戶，您將在收件箱中收到密碼重置說明。
      .desc 如果你需要任何幫助，請聯絡我們
      el-button(type="primary", size="small", @click="goStep('login')") 回到登入頁面
</template>

<script>
import { mapMutations } from 'vuex';
import {
  login, verifyOTP, getProfile, createOTP,
  forgetPassword,
} from '@/api/auth';

export default {
  name: 'Login',
  data() {
    return {
      step: 'login',
      email: '',
      password: '',
      validator: '',
      hasValidatorErr: false,
      binded: false,

      secret: '',
      otpURL: 'oauth',
    };
  },
  computed: {
    canLogin() {
      return this.email !== '' && this.password !== '';
    },
    canBind() {
      return this.validator !== '';
    },
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    goStep(step) {
      this.email = '';
      this.password = '';
      this.validator = '';
      this.step = step;
      this.hasValidatorErr = false;
    },
    async doLogin() {
      this.$execWithLoading(async () => {
        const rsp = await login(this.email, this.password);
        this.binded = rsp.otp;
        if (this.binded) {
          this.step = 'second-factor';
        } else {
          this.step = 'binding';
          const otpRsp = await createOTP();
          this.secret = otpRsp.code;
          this.otpURL = `otpauth://totp/${
            this.email
          }?secret=${
            this.secret
          }&issuer=${
            window.location.origin
          }`;
        }
        this.email = '';
        this.password = '';
      }, () => {
        this.$showError('登入失敗');
      });
    },
    async doBinding() {
      this.$execWithLoading(async () => {
        await verifyOTP(this.validator);
        this.validator = '';
        this.step = 'binded';
        this.binded = true;
      }, () => {
        this.$showError('綁定失敗，請聯絡管理員');
      });
    },
    async doValidate() {
      this.$execWithLoading(async () => {
        await verifyOTP(this.validator);
        this.validator = '';

        const profile = await getProfile();
        this.setUserInfo(profile);
        this.$router.push('/reserve');
      }, () => {
        this.$showError('驗證失敗');
      });
    },
    async doForget() {
      this.$execWithLoading(async () => {
        await forgetPassword(this.email);
        this.step = 'forget-send';
      });
    },
  },
  mounted() {
    this.setUserInfo(undefined);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.login-page {
  .left {
    background: #C0E5E4;
    flex: 1;
    .logo {
      background: white;
      flex: 0 0 383px;
      height: 80px;
      border-radius: 70px;
      img {
        width: 269px;
      }
    }
  }
  .right {
    background: white;
    flex: 0 0 415px;
    .form {
      flex: 1;
      padding: 40px;
      text-align: left;

      .title {
        font-size: 24px;
      }

      .link {
        color: $primary-color;
        text-decoration: underline;
      }

      .seperator {
        height: 1px;
        background: #DCDFE6;
      }

      .validate-err {
        color: $danger-color;
      }
    }
  }
}
</style>

<style lang="scss">
.validator {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
