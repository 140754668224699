import instance from './base';

export async function createAccounting(data) {
  const rsp = await instance.post('/v1/accounting/models', data);
  return rsp.data;
}

export async function getAccountings() {
  const rsp = await instance.get('/v1/accounting/models');
  return rsp.data;
}

export async function updateAccounting(id, name, publicStatus) {
  const rsp = await instance.put(`/v1/accounting/models/${id}`, {
    name,
    public: publicStatus,
  });
  return rsp.data;
}

export async function getAccountingAppointments(filter) {
  const rsp = await instance.get('/v1/accounting/appointments', {
    params: filter,
  });
  return rsp.data;
}

export async function getAccountingActivities(filter) {
  const rsp = await instance.get('/v1/accounting/activities', {
    params: filter,
  });
  return rsp.data;
}

export async function setAccountingMonth(appointments, month) {
  const data = {
    appointments: appointments.map((d) => ({
      ...d,
      accounting_month: month,
    })),
  };
  const rsp = await instance.put('/v1/accounting/appointments', data);
  return rsp.data;
}

export async function setActivityAccountingMonth(activities, month) {
  const data = {
    activities: activities.map((d) => ({
      ...d,
      accounting_month: month,
    })),
  };
  const rsp = await instance.put('/v1/accounting/activities', data);
  return rsp.data;
}

export async function getPaySlips(year, month, page, limit) {
  const rsp = await instance.get('/v1/accounting/payslips', {
    params: {
      accounting_month: `${year}-${month}`,
      page,
      limit,
    },
  });
  return rsp.data;
}

export async function getPaySlipDetail(id) {
  const rsp = await instance.get(`/v1/accounting/payslips/${id}`);
  return rsp.data;
}

export async function addPayslipItem(payslipID, name, amount) {
  const rsp = await instance.post(`/v1/accounting/payslips/${payslipID}/payment-items`, {
    name,
    amount,
  });
  return rsp.data;
}

export async function deletePayslipItem(payslipID, paymentID) {
  const rsp = await instance.delete(`/v1/accounting/payslips/${payslipID}/payment-items/${paymentID}`);
  return rsp.data;
}

export async function notifyPayslips(payslips) {
  const rsp = await instance.post('/v1/accounting/payslips/notification', { payslips });
  return rsp.data;
}

export async function getProfessionalMonthPayslip(professional, year, month) {
  const rsp = await instance.get(`/v1/professionals/${professional}/payslip/${year}/${month}`);
  return rsp.data;
}
