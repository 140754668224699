<template lang="pug">
.label-page.flex.column.align-start.gap-20
  .op.flex.align-center.gap-20
    el-button(
      id="btn-add-tag"
      @click="newTagVisible = true"
      type="primary", size="large", icon="el-icon-plus") 新增標籤
    .desc 建立標籤，方能在新增預約時為個案標記，公開標籤將會出現在清單上
  .table.hack-table
    el-table(:data="tags", width="430")
      el-table-column(label="標籤名稱" width="150")
        template(slot-scope="scope")
          template(v-if="scope.row.editMode")
            el-input(v-model="scope.row.editValue")
          template(v-else)
            .active(v-if="scope.row.public") {{ scope.row.name }}
            .inactive(v-else) {{ scope.row.name }}
      el-table-column(label="清單" width="200")
        template(slot-scope="scope")
          toggle(
            @input="updatePublic(scope.row, $event)"
            active-text="公開"
            inactive-text="隱藏"
            v-model="scope.row.public")
      el-table-column(label="操作" width="140")
          template(slot-scope="scope")
            .ops.flex.align-center
              template(v-if="scope.row.editMode")
                el-button(size="mini", @click="saveTag(scope.row)") 儲存
                el-button(size="mini", @click="cancelEdit(scope.row)") 取消
              template(v-else)
                el-button(size="mini", @click="startEdit(scope.row)") 編輯
  el-dialog(
    title="新增標籤"
    :visible.sync="newTagVisible"
    width="340px"
    :close-on-click-modal="false"
    )
    .form.flex.column.align-start.gap-4
      .column 標籤名稱
      el-input(v-model="newName", id="input-new-label-name")
    .dialog-footer(slot="footer")
      el-button(size="small") 取消
      el-button(
        id="btn-add-label-confirm"
        :disabled="newName === ''"
        size="small" type="primary" @click="newTag") 確定
</template>

<script>
import { updateTag, createTag, getTags } from '@/api/tags';

export default {
  data() {
    return {
      tags: [],
      newTagVisible: false,
      newName: '',
    };
  },
  methods: {
    async loadData() {
      await this.$execWithLoading(async () => {
        const data = await getTags();
        this.tags = data.map((d) => ({
          ...d,
          editMode: false,
          editValue: d.name,
        }));
      });
    },
    async saveTag(tag) {
      await this.$execWithLoading(async () => {
        await updateTag(tag.id, tag.editValue, tag.public);
        // eslint-disable-next-line no-param-reassign
        tag.name = tag.editValue;
        this.$showSuccess('標籤已更新');
      }, () => {
        this.$showError('更新標籤錯誤');
      });
      // eslint-disable-next-line no-param-reassign
      tag.editMode = false;
    },
    cancelEdit(tag) {
      // eslint-disable-next-line no-param-reassign
      tag.editMode = false;
    },
    startEdit(tag) {
      // eslint-disable-next-line no-param-reassign
      tag.editMode = true;
      // eslint-disable-next-line no-param-reassign
      tag.editValue = tag.name;
    },
    async updatePublic(tag, val) {
      await this.$execWithLoading(async () => {
        await updateTag(tag.id, tag.name, val);
        this.$showSuccess('標籤已更新');
      }, () => {
        this.$showError('更新標籤錯誤');
        // eslint-disable-next-line no-param-reassign
        tag.public = !val;
      });
    },
    async newTag() {
      await this.$execWithLoading(async () => {
        await createTag(this.newName, true);
        this.$showSuccess('標籤已新增');
        this.newTagVisible = false;
        this.newName = '';
        this.loadData();
      }, () => {
        this.$showError('新增標籤錯誤');
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.table {
  overflow: hidden;
  .inactive {
    color: $text-placeholder-color;
  }
}
</style>
