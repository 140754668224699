import instance from './base';

export async function createArchives(start, end) {
  await instance.post('/v1/user_records/archives', {
    start_from: start,
    end_at: end,
  });
}

export async function getArchives() {
  const rsp = await instance.get('/v1/user_records/archives', {

  });
  return rsp.data;
}
