import SettingLabel from '../views/setting/LabelPage.vue';
import SettingSpace from '../views/setting/SpacePage.vue';
import SettingPro from '../views/setting/ProfessionalPage.vue';
import SettingAccount from '../views/setting/AccountPage.vue';
import SettingAccounting from '../views/setting/AccountingPage.vue';
import SettingProject from '../views/setting/ProjectPage.vue';
import SettingOrganization from '../views/setting/OrganizationPage.vue';
import SettingRecordDownload from '../views/setting/RecordDownloadPage.vue';

export default [
  {
    path: 'label',
    name: 'SettingLabel',
    title: '標籤設定',
    component: SettingLabel,
    showInMenu: true,
  },
  {
    path: 'accounting',
    name: 'Accounting',
    title: '分帳模組',
    component: SettingAccounting,
    showInMenu: true,
  },
  {
    path: 'pro',
    name: 'SettingPro',
    title: '專業人士',
    component: SettingPro,
    showInMenu: true,
  },
  {
    path: 'space',
    name: 'SettingSpace',
    title: '空間設定',
    component: SettingSpace,
    showInMenu: true,
  },
  {
    path: 'organization',
    name: 'SettingOrganization',
    title: '專案設定',
    component: SettingOrganization,
    showInMenu: true,
  },
  {
    path: 'organization/:oid',
    props: true,
    name: 'SettingOrganizationProject',
    title: '專案設定',
    component: SettingProject,
    showInMenu: false,
  },
  {
    path: 'account',
    name: 'SettingAccount',
    title: '帳號設定',
    component: SettingAccount,
    showInMenu: true,
  },
  {
    path: 'record-download',
    name: 'RecordDownload',
    title: '個案記錄下載',
    component: SettingRecordDownload,
    showInMenu: true,
  },
];
