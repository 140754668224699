<template lang="pug">
.setting-page.flex.stretch
  .navbar.flex.column
    .nav.clickable.flex.align-center.justify-center(
      v-for="child in settingChildren"
      v-if="child.showInMenu"
      :class="{'active': child.name === menu}"
      @click="goPage(child)"
    ) {{ child.title }}
  router-view.sub-content
</template>

<script>
import { mapGetters } from 'vuex';
import settingChildren from '@/site/management/router/setting';

export default {
  data() {
    return {
      settingChildren,
      menu: 'SettingLabel',
    };
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
  computed: {
    ...mapGetters(['isAdmin', 'isAdvStaff']),
  },
  methods: {
    checkRoute() {
      if (this.$route.name === 'Setting') {
        this.goDftPage();
      } else {
        settingChildren.forEach((child) => {
          if (!child.showInMenu) {
            return;
          }
          if (this.$route.name.indexOf(child.name) >= 0) {
            this.menu = child.name;
          }
        });
      }
    },
    goPage(child) {
      this.menu = child.name;
      this.$router.push({ name: child.name });
    },
    goDftPage() {
      this.$router.push(`/setting/${settingChildren[0].path}`);
    },
  },
  mounted() {
    if (!this.isAdmin && !this.isAdvStaff) {
      this.$router.push('/reserve');
      return;
    }
    this.checkRoute();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.navbar {
  flex: 0 0 145px;
  padding: 20px;
  padding-right: 0;
  border-right: 1px solid $border-light-color;
  .nav {
    flex: 0 0 48px;
    margin-right: -1px;
    &.active {
      color: $primary-color;
      border-right: 1px solid $primary-color;
    }
  }
}
.sub-content {
  padding: 30px;
  flex: 1;
  overflow: hidden;
}
</style>
