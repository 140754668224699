<template lang="pug">
.record-download-page.flex.align-start.column.gap-20
  .desc 選擇年份和月份，將打包選定範圍的個案記錄，動作完成後即可下載檔案
  .flex.align-center.gap-12
    el-date-picker(format="yyyy-MM", v-model="month", type="month", placeholder="選擇年份和月份")
    el-button(type="primary", @click="downloadFile") 打包檔案
</template>

<script>
import {
  createArchives,
  getArchives,
} from '@/api/archive';

export default {
  data() {
    return {
      month: undefined,
    };
  },
  methods: {
    downloadFile() {
      console.log(this.month);
      const start = this.month;
      start.setMonth(start.getMonth(), 1);
      start.setHours(0, 0, 0, 0);
      const end = new Date(start);
      end.setMonth(start.getMonth() + 1, 1);
      console.log(start, end);
      this.$execWithLoading(async () => {
        await createArchives(this.$dateToTimestamp(start), this.$dateToTimestamp(end));
        await this.findArchive(this.$dateToTimestamp(start));
      }, (e) => {
        this.$showAxiosException('打包下載檔案失敗', e);
      });
    },
    async findArchive(start) {
      for (let retry = 0; retry < 5; retry += 1) {
        let target;
        // eslint-disable-next-line no-await-in-loop
        const list = await getArchives();
        list.forEach((l) => {
          if (l.start_from === start && l.status === 'done') {
            target = l;
          }
        });
        if (target) {
          window.open(target.url);
          return;
        }
        this.$waitMs(3000);
      }
      this.$showError('打包下載檔案失敗, 請稍後再試');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
