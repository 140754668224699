<template lang="pug">
.space-page.flex.column.align-stretch.gap-20
  .title.flex.align-center.gap-20
    .icon.flex.align-center.justify-center
      i.el-icon-back.clickable(@click="backToList")
    .title.font-size-18 {{ name }}
  .op.flex.align-center
    el-button(
      @click="showAdd"
      type="primary", size="large", icon="el-icon-plus") 新增專案
  .table.hack-table
    el-table(:data="projects")
      el-table-column(label="專案名稱", prop="name")
      el-table-column(label="諮商類別")
        template(slot-scope="scope")
          span {{ $msg(`project.type.${scope.row.main_requirement_type}`) }}
      el-table-column(label="價格與時長", width="180")
        template(slot-scope="scope")
          .flex.align-center.gap-6
            span NT $
            span {{ scope.row.price }}
            span ({{scope.row.duration}}分鐘)
      el-table-column(label="每人次數", width="80")
        template(slot-scope="scope")
          span 每人 {{ scope.row.limit_per_user || 0 }} 次
      el-table-column(label="專案額度", width="160")
        template(slot-scope="scope")
          .flex.align-center.gap-6
            span 專案已使用
            span {{ scope.row.used }} / {{ scope.row.total }}
      el-table-column(label="入帳方式")
        template(slot-scope="scope")
          span(v-if="scope.row.payment_received_by === 'professional'") 專業人士
          span(v-else) 機構
      el-table-column(label="清單")
        template(slot-scope="scope")
          toggle(
            @input="changePublic(scope.row)"
            active-text="公開"
            inactive-text="隱藏"
            v-model="scope.row.public")
      el-table-column(label="操作")
        template(slot-scope="scope")
          el-button(
            size="mini"
            @click="startEdit(scope.row)") 編輯
  project-editor(ref="projectEditor", @finish="finishEdit")
</template>

<script>
import {
  getOrganizationDetail,
  updateProjectPublic,
} from '@/api/project';
import ProjectEditorVue from '@/components/drawers/ProjectEditor.vue';

export default {
  components: {
    'project-editor': ProjectEditorVue,
  },
  props: {
    oid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      projects: [],
      name: '',
    };
  },
  methods: {
    showAdd() {
      this.$refs.projectEditor.$emit('show', { oid: this.oid });
    },
    backToList() {
      this.$router.push('/setting/organization');
    },
    finishEdit(result) {
      if (result) {
        this.loadData();
      }
    },
    async loadData() {
      return this.$execWithLoading(async () => {
        const rsp = await getOrganizationDetail(this.oid);
        this.projects = rsp.projects;
        this.name = rsp.name;
      }, () => {
        this.$showError('讀取失敗，請稍後重試');
      });
    },
    startEdit(data) {
      this.$refs.projectEditor.$emit('show', { oid: this.oid, data });
    },
    changePublic(project) {
      console.log(project);
      this.$execWithLoading(async () => {
        await updateProjectPublic(this.oid, project.id, project.public);
        this.$showSuccess('更新成功');
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.title {
  .icon {
    background: white;
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    border-radius: 10px;
    border: 1px solid $border-base-color;
    i {
      zoom: 0.8;
    }
  }
}
</style>
