<template lang="pug">
el-drawer(
  title="新增單位"
  :visible.sync="drawer"
  direction="rtl"
  :before-close="handleClose"
  :append-to-body="appendToBody")
  .drawer.flex.column.gap-20.default
    .data.flex.align-stretch.gap-6
      .flex.column.align-stretch.gap-6.flex-1
        .title 單位名稱
        el-input.input(
          v-model="name", placeholder="請輸入名稱")
    .op.flex.align-center-justify-stretch.gap-10
      el-button.button.flex-1(@click="cancel") 取消
      template(v-if="editMode")
        el-button.button.flex-1(@click="save", type="primary", :disabled="!valid") 更新
      template(v-else)
        el-button.button.flex-1(@click="save", type="primary", :disabled="!valid") 新增
</template>

<script>
import {
  addOrganization,
  updateOrganization,
} from '@/api/project';

export default {
  name: 'organization-editor',
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      name: '',
      editTarget: undefined,
    };
  },
  computed: {
    editMode() {
      return this.editTarget !== undefined;
    },
    valid() {
      return this.name !== '';
    },
  },
  methods: {
    async save() {
      return this.$execWithLoading(async () => {
        if (this.editMode) {
          await updateOrganization(this.editTarget.id, this.name, this.editTarget.public);
          this.$showSuccess('更新成功');
          this.close(true);
          return;
        }
        await addOrganization(this.name);
        this.$showSuccess('新增成功');
        this.close(true);
      });
    },
    handleClose(done) {
      done();
    },
    close(result) {
      this.$emit('finish', result);
      this.reset();
      this.drawer = false;
    },
    cancel() {
      this.close(false);
    },
    show() {
      this.drawer = true;
    },
    reset() {
      this.name = '';
      this.editTarget = undefined;
    },
  },
  mounted() {
    this.$on('show', (data) => {
      this.editTarget = data;
      this.name = data?.name || '';
      this.show();
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 20px;
  padding-top: 0;
}
</style>
