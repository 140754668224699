import Login from '../views/LoginPage.vue';
import Reserve from '../views/ReservePage.vue';
import Setting from '../views/SettingPage.vue';
import Revenue from '../views/RevenuePage.vue';
import Users from '../views/UsersPage.vue';
import Space from '../views/SpacePage.vue';
import PasswordForget from '../views/ForgetPassword.vue';
import Activity from '../views/ActivityPage.vue';
import DetailUserViewer from '../views/DetailUserViewer.vue';
import settingChildren from './setting';
import revenueChildren from './revenue';

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/reserve',
    name: 'Reserve',
    component: Reserve,
    needAuth: true,
  },
  {
    path: '/activity',
    name: 'Activity',
    component: Activity,
    needAuth: true,
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting,
    needAuth: true,
    children: settingChildren,
  },
  {
    path: '/revenue',
    name: 'Revenue',
    component: Revenue,
    needAuth: true,
    children: revenueChildren,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    needAuth: true,
  },
  {
    path: '/user/:id',
    name: 'UserDetail',
    component: DetailUserViewer,
    needAuth: true,
    props: true,
  },
  {
    path: '/space',
    name: 'Space',
    component: Space,
    needAuth: true,
  },
  {
    path: '/forget-password',
    name: 'PasswordForget',
    component: PasswordForget,
  },
  {
    path: '*',
    redirect: '/reserve',
  },
];
