<template lang="pug">
el-drawer(
  title="編輯館別"
  :visible.sync="drawer"
  direction="rtl"
  :before-close="handleClose")
  .drawer.flex.column.gap-20
    .data.flex.column.gap-6
      .title 館別名稱*
      .input.flex.align-center.justify-stretch
        el-input.input(v-model="name", placeholder="請輸入館別名稱")
    .data.flex.column.gap-6
      .title 空間名稱*
      .input.flex.align-center.justify-stretch.gap-10
        el-input.input(placeholder="請輸入空間名稱", v-model="newSpaceName")
        el-button(type="primary", icon="el-icon-plus",
          @click="addNewSpace", :disabled="newSpaceName === ''") 加入
    .spaces.flex.column.gap-20
      .space.flex.align-center.justify-stretch.gap-10(v-for="space in spaces")
        el-input(v-model="space.name")
        el-button(icon="el-icon-delete")
    .existed-space.flex.column.gap-10
      .text-primary 已存在空間名稱
      draggable.flex.column.gap-20(v-model="origSpaces" draggable=".space")
        .space.flex.align-center.justify-stretch.gap-10(
          v-for="space in origSpaces" :key="space.id"
        )
          i.el-icon-d-caret
          toggle(
            active-text="公開"
            inactive-text="隱藏"
            v-model="space.public")
          el-input(v-model="space.name")
    .op.flex.align-center-justify-stretch.gap-10
      el-button.button(@click="cancel") 取消
      el-button.button(@click="updateBranch", type="primary") 更新
</template>

<script>
import { updateBranch } from '@/api/place';
import draggable from 'vuedraggable';

export default {
  name: 'place-edit',
  components: {
    draggable,
  },
  data() {
    return {
      drawer: false,

      newSpaceName: '',

      origData: undefined,
      name: '',
      spaces: [],
      origSpaces: [],
    };
  },
  methods: {
    handleClose(done) {
      done();
    },
    show() {
      this.drawer = true;
    },
    addNewSpace() {
      this.spaces.push({ name: this.newSpaceName, public: true });
      this.newSpaceName = '';
    },
    reset() {
      this.newSpaceName = '';
      this.spaces = [];
      this.origSpaces = [];
      this.name = '';
      this.origData = undefined;
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
      this.reset();
    },
    cancel() {
      this.close(false);
    },
    async updateBranch() {
      await this.$execWithLoading(async () => {
        await updateBranch(
          this.origData.id,
          this.name,
          this.origData.public,
          this.origSpaces,
          this.spaces.map((space) => space.name),
        );
        this.$showSuccess('設定更新');
        this.close(true);
      }, (e) => {
        console.log(e);
        this.$showError('設定失敗，請稍後重試');
      });
    },
    loadBranch(data) {
      this.origData = data;
      this.name = data.name;
      this.origSpaces = JSON.parse(JSON.stringify(data.rooms));
    },
  },
  mounted() {
    this.$on('show', (data) => {
      console.log(data);
      this.reset();
      this.loadBranch(data);
      this.show();
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 0 20px;
  padding-bottom: 20px;
  .op {
    .button {
      flex: 1;
    }
  }
}
</style>
