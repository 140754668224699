<template lang="pug">
el-drawer(
  :title="title"
  :visible.sync="drawer"
  direction="rtl"
  :before-close="handleClose"
  :append-to-body="appendToBody")
  .drawer.flex.column.gap-20
    .flex.column.align-stretch.gap-6
      .title 專案名稱*
      el-input.input(
        v-model="name", placeholder="請輸入名稱")
    .flex.column.align-stretch.gap-6
      .title 諮詢類別*
      el-select(
        :disabled="readOnly"
        v-model="projectType", placeholder="請選擇")
        el-option(
          v-for="t in types", :key="t.value"
          :label="t.label", :value="t.value")
    .flex.column.align-stretch.gap-6
      .title 專案價格*
      el-input.input(
        :disabled="readOnly"
        type="number"
        v-model="price")
        template(slot="prepend") NT$
    .flex.column.align-stretch.gap-6
      .title 預約時長*
      el-select(
        :disabled="readOnly"
        v-model="length", placeholder="請選擇時長")
        el-option(
          v-for="l in lengthOptions", :key="l.value"
          :label="l.label", :value="l.value")
    .flex.column.align-stretch.gap-6
      .title 每人次數*
      el-input-number.input(
        :disabled="readOnly"
        :min="0"
        step-strictly
        :step="1"
        v-model="personTime")
    .flex.column.align-stretch.gap-6
      .title 專案額度*
      el-input-number.input(
        :disabled="readOnly"
        :min="0"
        step-strictly
        :step="1"
        v-model="total")
    .flex.column.align-stretch.gap-6
      .title 入帳方式*
      el-radio-group.flex.align-cetner.gap-6(v-model="payment")
        el-radio(
        :disabled="readOnly"
        label="organization" border) 機構
        el-radio(
        :disabled="readOnly"
        label="professional" border) 專業人士
    .flex.column.align-start.gap-6
      .title 指定個案記錄範本
      el-button(type="primary", @click="addUpload") 點此上傳
      input.file-input(
        type="file", ref="upload",
        @input="changeFile")
      .files.flex.column.align-start.gap-6
        .file.flex.align-center.gap-6(v-for="(f, idx) in files", :key="idx")
          i.el-icon-document
          .name {{ f.name }}
          i.el-icon-delete.danger-color(@click="files.splice(idx, 1)")
    .op.flex.align-center.justify-stretch.gap-10
      el-button.button.flex-1(@click="cancel") 取消
      template(v-if="editMode")
        el-button.button.flex-1(@click="save", type="primary") 更新
      template(v-else)
        el-button.button.flex-1(@click="save", type="primary") 新增
</template>

<script>
import {
  uploadTemplateFile,
  createProject,
  updateProject,
} from '@/api/project';

export default {
  name: 'project-editor',
  props: {
    appendToBody: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    editMode() {
      return this.editTarget !== undefined;
    },
    title() {
      if (this.editMode) {
        return '編輯專案';
      }
      return '新增專案';
    },
    readOnly() {
      return this.editMode && this.editTarget?.used !== 0;
    },
  },
  data() {
    return {
      oid: '',
      drawer: false,
      editTarget: undefined,

      types: [
        { value: 'mental', label: '心理諮詢' },
        { value: 'law', label: '法律諮詢' },
        { value: 'finance', label: '財務諮詢' },
        { value: 'management', label: '組織管理' },
        { value: 'medical', label: '健康管理' },
      ],
      lengthOptions: [
        { value: '30', label: '30分鐘' },
        { value: '60', label: '60分鐘' },
        { value: '90', label: '90分鐘' },
        { value: '120', label: '120分鐘' },
        { value: '150', label: '150分鐘' },
        { value: '180', label: '180分鐘' },
      ],

      name: '',
      projectType: '',
      price: '',
      length: '',
      personTime: 0,
      total: 0,
      payment: 'organization',
      files: [],
    };
  },
  methods: {
    reset() {
      this.name = '';
      this.projectType = '';
      this.price = '';
      this.length = '';
      this.personTime = 0;
      this.total = 0;
      this.payment = 'organization';
      this.files = [];
      this.editTarget = undefined;
    },
    addUpload() {
      this.$refs.upload.click();
    },
    async changeFile() {
      if (this.$refs.upload.files.length < 1) {
        return;
      }
      this.$execWithLoadingMessage('檔案上傳中', async () => {
        const file = this.$refs.upload.files[0];

        const rsp = await uploadTemplateFile(this.oid, file);

        this.files.push({
          name: file.name,
          id: rsp.id,
          file,
        });
        this.$refs.upload.value = '';
      });
    },
    checkInput() {
      const errs = this.$initInputError();
      if (this.name === '') {
        errs.add('名稱', '請輸入名稱');
      }
      if (this.projectType === '') {
        errs.add('諮詢類別', '請選擇諮詢類別');
      }
      if (this.price === '') {
        errs.add('專案價格', '請輸入專案價格');
      }
      if (this.length === '') {
        errs.add('預約時長', '請選擇預約時長');
      }
      if (this.personTime <= 0) {
        errs.add('每人次數', '每人次數須為正數');
      }
      if (this.total <= 0) {
        errs.add('專案額度', '專案額度需為正數');
      }

      if (errs.hasError) {
        const msg = errs.warnMessage;
        this.$showWarn(msg.title, msg.message);
        return false;
      }

      return true;
    },
    cancel() {
      this.close(false);
    },
    save() {
      if (!this.checkInput()) {
        return;
      }
      let data = {
        name: this.name,
        price: parseInt(this.price, 10),
        duration: parseInt(this.length, 10),
        payment_received_by: this.payment,
        templates: this.files.map((f) => f.id),
        main_requirement_type: this.projectType,
        total: parseInt(this.total, 10),
        limit_per_user: parseInt(this.personTime, 10),
      };
      if (this.editTarget !== undefined) {
        // update mode
        this.$execWithLoading(async () => {
          if (this.readOnly) {
            data = {
              name: this.name,
              templates: this.files.map((f) => f.id),
            };
          }
          await updateProject(this.oid, this.editTarget.id, data);
          this.$showSuccess('更新成功');
          this.close(true);
        });
      } else {
        // create mode
        this.$execWithLoading(async () => {
          await createProject(this.oid, data);
          this.$showSuccess('新增成功');
          this.close(true);
        });
      }
    },
    handleClose(done) {
      this.reset();
      done();
    },
    close(result) {
      this.$emit('finish', result);
      this.drawer = false;
    },
    show() {
      this.drawer = true;
    },
    loadData() {
      const t = this.editTarget;
      if (t === undefined) {
        this.reset();
        return;
      }
      this.name = t.name;
      this.projectType = t.main_requirement_type;
      this.price = t.price;
      this.length = t.duration;
      this.personTime = t.limit_per_user;
      this.total = t.total;
      this.payment = t.payment_received_by;
      this.files = t.templates.map((f) => ({
        name: f?.name.replace(/^\('/g, '').replace(/',\)$/g, ''),
        id: f.id,
      }));
    },
  },
  mounted() {
    this.$on('show', ({ oid, data }) => {
      this.oid = oid;
      this.editTarget = data;
      this.loadData();
      this.show();
    });
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  padding: 20px;
  padding-top: 0;
}
.file-input {
  max-height: 0;
  max-width: 0;
  overflow: hidden;
}
</style>
