<template lang="pug">
.pro-list.flex.column.gap-20
  .op.flex.align-center.gap-20
    el-button(
      @click="newProfessional"
      type="primary", size="large", icon="el-icon-plus") 新增專業人士
  .table.hack-table
    el-table(:data="professionals", style="max-width: 901px",
      :default-sort="{'prop': 'public'}")
      el-table-column(label="專業人士姓名", width="130")
        template(slot-scope="scope")
          span(v-if="scope.row.public") {{ scope.row.name }}
          span.text-placeholder(v-else) {{ scope.row.name }}
      el-table-column(label="電子信箱", width="320")
        template(slot-scope="scope")
          span(v-if="scope.row.public") {{ scope.row.email }}
          span.text-placeholder(v-else) {{ scope.row.email }}
      el-table-column(label="清單", width="120")
        template(slot-scope="scope")
          toggle(
            @input="updatePublic(scope.row, $event)"
            active-text="公開"
            inactive-text="隱藏"
            v-model="scope.row.public")
      el-table-column(label="雙重驗證", width="130")
        template(slot-scope="scope")
          .status.flex.align-center.gap-10
            template(v-if="scope.row['2pa']")
              .icon.enable
                i.el-icon-check
              span 完成驗證
            template(v-else)
              .icon.disable
                i.el-icon-close
              span 尚未驗證
      el-table-column(label="操作" width="200")
          template(slot-scope="scope")
            .ops.flex.align-center
              el-button(size="mini", @click="startEdit(scope.row)") 編輯
              template(v-if="scope.row['2pa']")
                el-button(size="mini", @click="deleteAccountSecond(scope.row)") 解除綁定驗證
  pro-editor(ref="proEditor", @finish="finishEdit")
</template>

<script>
import {
  getProfessionalList,
  // updateProfessional,
  updateProfessionalPublic,
} from '@/api/professionals';
import {
  deleteProfessionalToken,
} from '@/api/management';
import ProfessionalEditor from '@/components/drawers/ProfessionalEditor.vue';

export default {
  components: {
    'pro-editor': ProfessionalEditor,
  },
  data() {
    return {
      newProVisible: false,
      professionals: [],
    };
  },
  methods: {
    finishEdit(result) {
      if (result) {
        this.loadData();
      }
    },
    loadData() {
      this.$execWithLoading(this.loadList);
    },
    async loadList() {
      const rsp = await getProfessionalList();
      this.professionals = rsp.map((p) => ({
        ...p,
        name: `${p.family_name}${p.given_name}`,
      }));
    },
    async updatePublic(pro, value) {
      console.log({ pro, value });
      this.$execWithLoading(async () => {
        await updateProfessionalPublic(pro.id, value);
        // eslint-disable-next-line no-param-reassign
        this.$showSuccess('更新成功');
        await this.loadList();
      }, () => {
        this.$showError('更新失敗，請稍後再試');
        // eslint-disable-next-line no-param-reassign
        pro.public = !value;
      });
    },
    async deleteAccountSecond(account) {
      return this.$execWithLoading(async () => {
        await deleteProfessionalToken(account.id);
        this.$showSuccess('已解除綁定');
        // eslint-disable-next-line no-param-reassign
        account['2pa'] = false;
      }, () => {
        this.$showError('更新失敗，請稍後重試');
      });
    },
    startEdit(professional) {
      this.$refs.proEditor.$emit('show', professional);
    },
    newProfessional() {
      this.$refs.proEditor.$emit('show');
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.table {
  .status {
    .icon {
      width: 14px;
      height: 14px;
      flex: 0 0 14px;
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        zoom: 0.5;
        color: white;
        font-weight: bold;
      }
      &.enable {
        background: $success-color;
      }
      &.disable {
        background: $danger-color;
      }
    }
  }
}
</style>
