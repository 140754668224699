<template lang="pug">
.space-page.flex.column.align-start
  .op.flex.align-center
    el-button(
      @click="$refs.branchCreator.$emit('show')"
      type="primary", size="large", icon="el-icon-plus") 新增館別
  .content.overflow-overlay.flex.column.align-start.gap-20
    .branch.flex.column(
      v-for="branch in branches"
      :key="branch.id")
      .title.flex.align-center.gap-10
        i.icon.el-icon-arrow-right.clickable(@click="toggleBranchExpand(branch)")
        .text.clickable(@click="toggleBranchExpand(branch)") {{ branch.name }}
        toggle(
          @input="changePublic(branch)"
          active-text="公開"
          inactive-text="隱藏"
          v-model="branch.public"
        )
        el-button(type="primary" size="mini", @click="startEdit(branch)") 編輯
      template(v-if="branch.expand")
        .rooms.flex.column.gap-10
          .empty-room(v-if="branch.rooms.length === 0") 目前無空間資料,請點選編輯按鈕新增空間
          .room(v-else v-for="room in branch.rooms")
            .enable(v-if="room.public") {{ room.name }}
            .disable(v-else) {{ room.name }} (隱藏)
  branch-creator(ref="branchCreator", @finish="handleFinishCreate")
  branch-editor(ref="branchEditor", @finish="handleFinishEdit")
</template>

<script>
import { getBranches, updateBranch } from '@/api/place';
import BranchCreator from '@/components/drawers/BranchCreator.vue';
import BranchEditor from '@/components/drawers/BranchEdit.vue';

export default {
  components: {
    'branch-creator': BranchCreator,
    'branch-editor': BranchEditor,
  },
  data() {
    return {
      branches: [],
    };
  },
  methods: {
    async loadData() {
      return this.$execWithLoading(async () => {
        const data = await getBranches();
        this.branches = data.map((b) => ({
          ...b,
          expand: false,
        }));
      }, () => {
        this.$showError('讀取館別失敗，請稍後重試');
      });
    },
    toggleBranchExpand(branch) {
      // eslint-disable-next-line no-param-reassign
      branch.expand = !branch.expand;
    },
    startEdit(branch) {
      this.$refs.branchEditor.$emit('show', branch);
    },
    handleFinishCreate(result) {
      if (result) {
        this.loadData();
      }
    },
    handleFinishEdit(result) {
      if (result) {
        this.loadData();
      }
    },
    async changePublic(branch) {
      if (!branch.public) {
        try {
          await this.$confirm('此動作會從選單上隱藏館別和空間，將無法選取', '提醒', {
            confirmButtonText: '確定',
            cancelButtonText: '取消',
            type: 'warning',
          });
        } catch (e) {
          // eslint-disable-next-line no-param-reassign
          branch.public = true;
          return;
        }
      }
      await this.$execWithLoading(async () => {
        await updateBranch(branch.id, branch.name, branch.public, branch.rooms, []);
        this.$showSuccess('更新成功');
        setTimeout(() => {
          this.loadData();
        });
      }, () => {
        this.$showError('更新失敗');
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.content {
  margin-left: -20px;
  padding: 20px;
}
.branch {
  width: 500px;
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08),
    0px 2px 6px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  .title {
    flex: 0 0 64px;
    padding: 0 16px;
    .icon {
      flex: 0 0 auto;
    }
    .text {
      flex: 1;
    }
  }
  .rooms {
    border-top: 1px solid #CCC;
    padding: 20px;
    .empty-room {
      color: $text-placeholder-color;
    }
    .room {
      .disable {
        color: $text-placeholder-color;
      }
    }
  }
}
</style>
