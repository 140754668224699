<template lang="pug">
el-drawer(
  title="新增專業人士"
  :visible.sync="drawer"
  :close-on-press-escape="false"
  :wrapperClosable="false"
  direction="rtl"
  :before-close="handleClose")
  .drawer.flex.column.gap-20
    .data.flex.column.align-stretch.gap-6
      .title 姓氏*
      el-input(v-model.trim="familyName", placeholder="請輸入專業人士姓氏")
    .data.flex.column.align-stretch.gap-6
      .title 名字*
      el-input(v-model.trim="givenName", placeholder="請輸入專業人士名字")
    .data.flex.column.align-stretch.gap-6
      .title Email*
      .alert.text-danger(v-if="origData && email !== origData?.email") 變更 email 需重新進行雙重驗證的綁定
      el-input(v-model.trim="email", placeholder="請輸入 Email")
    .data.flex.column.align-stretch.gap-6
      .title 登記館別*
      place-selector.place-input(
        :showAll="false"
        v-model="branch", @input="changeBranch")
    .data.flex.column.align-start.gap-6
      .title 照片*
      .photo.flex.column.align-start.gap-10
        el-button(type="primary", @click="upload") 點此上傳
        input(
          type="file", ref="upload",
          @input="changeFile")
        .preview.flex.align-end.gap-10(v-if="avatarPreview !== ''")
          img(:src="avatarPreview")
          .remove-icon.flex.align-center.justify-center.clickable(@click="removeAvatar")
            i.el-icon-close
    .data.flex.column.align-stretch.gap-6
      .title 職稱
      el-input(v-model.trim="position", placeholder="請輸入職稱")
    template(v-if="editMode")
      .data.flex.column.align-start.gap-6
        .title 專業人士帳號
        template(v-if="this.origData")
          el-button(type="danger", v-if="this.origData.enable", @click="updateStatus(false)") 停用帳號
          el-button(type="success", v-else, @click="updateStatus(true)") 啟動帳號
    .op.flex.align-center-justify-stretch.gap-10
      el-button.button(@click="cancel") 取消
      template(v-if="editMode")
        el-button.button(@click="save", type="primary", :disabled="!valid") 更新
      template(v-else)
        el-button.button(@click="save", type="primary", :disabled="!valid") 新增
</template>

<script>
import {
  newProfessional,
  updateProfessional,
  updateProfessionalStatus,
  createAvatar,
} from '@/api/professionals';

export default {
  name: 'pro-editor',
  data() {
    return {
      origData: undefined,
      drawer: false,

      familyName: '',
      givenName: '',
      email: '',
      position: '',
      avatar: '',
      avatarPreview: '',
      file: undefined,
      branch: undefined,
    };
  },
  computed: {
    valid() {
      if (this.familyName === '') {
        return false;
      }
      if (this.givenName === '') {
        return false;
      }
      if (this.email === '') {
        return false;
      }
      if (this.avatar === '') {
        return false;
      }
      return true;
    },
    editMode() {
      return this.origData !== undefined;
    },
  },
  methods: {
    changeBranch(e) {
      console.log(e);
    },
    handleClose(done) {
      done();
    },
    show() {
      this.drawer = true;
    },
    close(result) {
      this.reset();
      this.drawer = false;
      this.$emit('finish', result);
    },
    loadData(pro) {
      this.origData = pro;

      // eslint-disable-next-line prefer-destructuring
      this.familyName = this.origData.family_name;
      this.givenName = this.origData.given_name;
      this.email = this.origData.email;
      this.position = this.origData.title;
      this.avatar = this.origData.avatar.id;
      this.avatarPreview = this.origData.avatar.url;
      this.branch = undefined;
      this.$nextTick(() => {
        const ids = this.origData.branches.map((b) => b.id);
        if (ids.length > 0) {
          [this.branch] = ids;
        }
      });
    },
    upload() {
      this.$refs.upload.click();
    },
    async changeFile() {
      if (this.$refs.upload.files.length < 1) {
        return;
      }
      this.$execWithLoading(async () => {
        const file = this.$refs.upload.files[0];
        const rsp = await createAvatar(file);
        console.log(rsp, rsp.id);
        this.avatar = rsp.id;
        this.file = file;
        this.avatarPreview = URL.createObjectURL(file);
      });
    },
    removeAvatar() {
      this.avatarPreview = '';
      this.file = undefined;
      if (this.$refs.upload) {
        this.$refs.upload.value = '';
      }
    },
    reset() {
      this.origData = undefined;
      this.drawer = false;
      this.familyName = '';
      this.givenName = '';
      this.email = '';
      this.position = '';
      this.avatar = '';
      this.branch = undefined;
      this.removeAvatar();
    },
    cancel() {
      this.close(false);
    },
    save() {
      this.$execWithLoading(async () => {
        if (this.editMode) {
          await updateProfessional(
            this.origData.id,
            this.familyName, this.givenName, this.email, this.avatar, true, this.position,
            [this.branch],
          );
          this.$showSuccess('更新成功');
          this.close(true);
        } else {
          await newProfessional(
            this.familyName, this.givenName, this.email, this.avatar, true, this.position,
            [this.branch],
          );
          this.$showSuccess('新增成功');
          this.close(true);
        }
      }, (e) => {
        if (this.editMode) {
          this.$showAxiosException('更新失敗', e);
        } else {
          this.$showAxiosException('新增失敗', e);
        }
      });
    },
    async updateStatus(status) {
      let msg = '停用此帳號，專業人士將不能登入帳戶';
      let alertMsg = '此帳號已停用';
      if (status) {
        msg = '啟用此帳號，專業人士需重新完成雙重驗證';
        alertMsg = '此帳號已啟用';
      }
      try {
        await this.$confirm(msg, '提醒', {
          confirmButtonText: '確定',
          cancelButtonText: '取消',
          type: 'warning',
        });
      } catch (e) {
        return;
      }
      this.$execWithLoading(async () => {
        await updateProfessionalStatus(this.origData.id, status);
        this.origData.enable = status;
        if (!status) {
          this.origData.public = false;
        }
        this.$forceUpdate();
        this.$showSuccess(alertMsg);
      });
    },
  },
  mounted() {
    this.$on('show', (data) => {
      this.reset();
      if (data) {
        this.loadData(data);
      }
      this.show();
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/style/variables.scss';

.drawer {
  padding: 20px;
  .button {
    flex: 1;
  }
  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
  input[type=file] {
    display: none;
  }
  .preview {
    .remove-icon {
      color: $danger-color;
      width: 13px;
      height: 13px;
      border-radius: 100%;
      border: 1px solid $danger-color;
      i {
        zoom: 0.6;
      }
    }
  }
}
</style>
