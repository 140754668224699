<template lang="pug">
.buttons.flex.align-center.gap-4
  el-button(
      v-if="chatID"
      type="primary", size="mini", @click="openChat") 開啟 LINE 對話
  el-button(size="mini", plain, @click="showEditChatID") 編輯 ChatID
</template>

<script>
import {
  getUser,
  updateUser,
} from '@/api/user';
import constant from '@/util/constant';

export default {
  name: 'line-chat-id-edit-button',
  props: {
    id: {
      type: String,
      required: true,
    },
    uid: {
      type: [String, Number],
      required: true,
    },
  },
  watch: {
    id() {
      this.chatID = this.id;
    },
  },
  data() {
    return {
      chatID: '',
    };
  },
  methods: {
    openChat() {
      this.$goLink(`${constant.lineChatURL}/${this.chatID}`);
    },
    async showEditChatID() {
      let chatID = '';
      if (this.chatID) {
        chatID = this.chatID;
      }
      try {
        console.log(this.$prompt);
        const result = await this.$prompt('請輸入 LINE Chat ID', '', {
          confirmButtonText: '儲存',
          cancelButtonText: '取消',
          inputValue: chatID,
        });
        chatID = result.value;
      } catch (e) {
        console.log('cancel');
      }

      console.log(chatID);
      if (!chatID) {
        this.$showError('請輸入有效的 ChatID');
        return '';
      }
      this.chatID = chatID;
      let origID = '';
      return this.$execWithLoading(async () => {
        const data = await getUser(this.uid);
        origID = data.line_chat_id;
        data.line_chat_id = chatID;
        await updateUser(this.uid, this.generateUserFromOrigData(data));
        this.$emit('update', chatID);
        this.$showSuccess('設定成功');
      }, (e) => {
        this.chatID = origID;
        this.$showAxiosException('更新失敗', e);
      });
    },
    generateUserFromOrigData(data) {
      return {
        family_name: data.family_name,
        given_name: data.given_name,
        gender: data.gender,
        birthday: data.birthday,
        id_number: data.id_number,
        passport: data.passport,
        phone: data.phone,
        email: data.email,
        country: data.country,
        county: data.county,
        district: data.district,
        address: data.address,
        relationship: data.relationship,
        education: data.education,
        industry: data.industry,
        job: data.job,
        emergency_person: data.emergency_person,
        emergency_relationship: data.emergency_relationship,
        emergency_contact: data.emergency_contact,
        prefer_language: data.prefer_language,
        refer_source: data.refer_source,
        therapy_history: data.therapy_history,
        suicide_record: data.suicide_record,
        wanna_be_called: data.wanna_be_called,
        line_chat_id: data.line_chat_id,
      };
    },
  },
  mounted() {
    this.chatID = this.id;
  },
};
</script>
