<template lang="pug">
el-table.reserve-list-table(:data="reserveList")
  el-table-column(width="30")
    template(slot-scope="scope")
      el-checkbox(v-model="scope.row.checked", :disabled="scope.row.confirm_status !== 'confirmed'")
  el-table-column(label="預約日期1", width="120")
    template(slot-scope="scope")
      .td-time.flex.align-center
      span
        span {{ $timestampToDateWithDay(scope.row.start_at) }}
  el-table-column(label="個案姓名")
    template(slot-scope="scope")
      .td-name.flex.align-center.justify-space-between
        .names.flex.align-center.wrap
          .name {{ scope.row.user.family_name }}{{ scope.row.user.given_name }}
        .note(v-if="scope.row.note")
          el-tooltip(:content="scope.row.note", placement="right")
            i.el-icon-chat-dot-round.clickable
  el-table-column(label="專業人士", width="130")
    template(slot-scope="scope")
      pro-badge(:class="{'cancel-text': scope.row.isCancelled}" :professional="scope.row.professional")
  el-table-column(label="支付記錄", width="150")
    template(slot-scope="scope")
      template(v-if="scope.row.project")
        span(:class="{'cancel-text': scope.row.isCancelled}") {{ scope.row.projectName }}
      template(v-else-if="scope.row.isCancelled")
        .flex.align-center.gap-6
          span(:class="{'cancel-text-with-event': scope.row.isCancelled}")
            template(v-if="scope.row.payment_status === '3rdparty'")
              span 申請退款中
              el-popover(trigger="hover", placement="top-start"
                title="藍新支付ID", :content="scope.row.newebpay_trx_id")
                i.el-icon-info(slot="reference")
            template(v-else-if="scope.row.payment_status === '3rdparty_refunded'")
              span 申請退款中
              el-popover(trigger="hover", placement="top-start"
                title="藍新支付ID", :content="scope.row.newebpay_trx_id")
                i.el-icon-info(slot="reference")
            template(v-else) ---
      template(v-else)
        .flex.align-center.gap-6
          span.danger-color(v-if="scope.row.payment_status === 'notpaid'") 尚未支付
          span(v-else-if="scope.row.payment_status === 'cash'") 現金支付
          span(v-else-if="scope.row.payment_status === '3rdparty'")
            span 藍新金流
            el-popover(trigger="hover", placement="top-start"
              title="藍新支付ID", :content="scope.row.newebpay_trx_id")
              i.el-icon-info(slot="reference")
          span(v-else-if="scope.row.payment_status === 'creditcard'") 信用卡支付
          span(v-else-if="scope.row.payment_status === 'transfer'") 轉帳支付
          el-dropdown(
            v-if="(scope.row.canChangePaid || isAdmin) && paidCanChange(scope.row.payment_status)"
            @command="updatePayment(scope.row, $event)", trigger="click")
            el-button.dropdown-button(icon="el-icon-arrow-down", size="mini")
            el-dropdown-menu(slot="dropdown")
              el-dropdown-item(command='notpaid') 尚未付款
              el-dropdown-item(command='cash') 現金支付
              el-dropdown-item(command='transfer') 轉帳支付
              el-dropdown-item(command='creditcard') 信用卡
  el-table-column(label="分帳模組", width="150")
    template(slot-scope="scope")
      .flex.align-center.gap-4
        span {{ scope.row.accounting_model.name }}
        i.el-icon-edit.text-clickable(@click="$emit('edit', scope.row)")
  el-table-column(label="總金額", width="100")
    template(slot-scope="scope")
      span NT ${{ scope.row.price }}
  el-table-column(label="機構收益", width="100")
    template(slot-scope="scope")
      span NT ${{ scope.row.organization_profit }}
  el-table-column(label="專業人士收益", width="120")
    template(slot-scope="scope")
      span NT ${{ scope.row.professional_profit }}
  template(v-if="isProject")
    el-table-column(label="入帳", width="80")
      template(slot-scope="scope")
        span(v-if="scope.row.project.payment_received_by === 'organization'") 機構
        span(v-else) 專業人士
    el-table-column(label="專業人士實收", width="120")
      template(slot-scope="scope")
        span(v-if="scope.row.project.payment_received_by === 'organization'") {{ scope.row.professional_profit }}
        span(v-else) -{{ scope.row.organization_profit }}
  el-table-column(label="專業人士確認", width="120")
    template(slot-scope="scope")
      span.confirm-status(:class="scope.row.confirm_status") {{ $msg(`confirm_status.${scope.row.confirm_status}`) }}
  el-table-column(label="財管", width="180")
    template(slot-scope="scope")
      .accounting-month-cell.flex.align-center.gap-12
        template(v-if="scope.row.confirm_status === 'confirmed'")
          span(v-if="scope.row.payslip") {{ scope.row.monthStr }}
          el-date-picker.minimize-picker(
            type="month", v-model="scope.row.month", @change="changeAccountingMonth(scope.row, $event)")
        template(v-else)
          span 尚未選擇
</template>

<script>
import { mapGetters } from 'vuex';
import { setAccountingMonth, getProfessionalMonthPayslip } from '@/api/accounting';
import { paddingToN } from '@/util/format';
import {
  updatePayment,
} from '@/api/reserve';

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    hideColumn: {
      type: Array,
      default: () => [],
    },
    isProject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reserveList: [],
      hideColumnMap: {},
    };
  },
  computed: {
    ...mapGetters(['isAdmin', 'isAdvStaff']),
  },
  watch: {
    data() {
      this.setupList();
    },
  },
  methods: {
    paidCanChange(payment) {
      return ['notpaid', 'cash', 'transfer', 'creditcard'].indexOf(payment) >= 0;
    },
    updatePayment(appointment, method) {
      this.$execWithLoading(async () => {
        await updatePayment(appointment.id, method);
        // eslint-disable-next-line no-param-reassign
        appointment.payment_status = method;
        this.$showSuccess('更新成功');
      }, () => {
        this.$showError('更新失敗，請稍後再試');
      });
    },
    setupList() {
      this.reserveList = this.data.map((d) => ({
        ...d,
        month: undefined,
        monthStr: d.payslip ? `${d.payslip.year}-${paddingToN(d.payslip.month, 2)}` : '選擇結帳月份',
      }));
      this.hideColumn.forEach((col) => {
        this.hideColumnMap[col] = true;
      });
    },
    async checkAccountingMonthEditable(appointment, value) {
      let confirmMsg = '';
      if (appointment?.payslip?.status && appointment?.payslip?.status !== 'not_notified') {
        if (appointment?.payslip?.status === 'confirmed') {
          this.$showWarn('禁止編輯', '該預約對帳月份已確認，不可編輯');
          throw Error('forbidden');
        }
        confirmMsg = `原本的財管月已送出通知，修改後會重置 ${appointment.payslip.year} / ${appointment.payslip.month} 狀態，確定要變更嗎？`;
      }

      if (confirmMsg === '') {
        try {
          const rsp = await getProfessionalMonthPayslip(appointment.professional.id, value.getFullYear(), value.getMonth() + 1);
          if (rsp?.confirm_status && rsp?.confirm_status !== 'not_notified') {
            confirmMsg = `${confirmMsg}${confirmMsg ? '此外，' : ''}修改後的財管月已送出通知，修改後會重置 ${value.getFullYear()} / ${value.getMonth() + 1} 狀態，確定要變更嗎？`;
          }
        } catch (e) {
          console.log('getProfessionalMonthPayslip fail', e);
        }
      }
      if (confirmMsg === '') {
        return;
      }
      // eslint-disable-next-line consistent-return
      return this.$confirm(confirmMsg, '修改確認', {
        confirmButtonText: '進行修改',
        cancelButtonText: '取消',
        type: 'warning',
      });
    },
    async changeAccountingMonth(appointment, value) {
      console.log({ appointment, value });
      return this.$execWithLoading(async () => {
        await this.checkAccountingMonthEditable(appointment, value);
        return this.changeMultipleAccountingMonth([appointment], value);
      }, (e) => {
        console.log(e);
        // eslint-disable-next-line no-param-reassign
        appointment.month = undefined;
        this.$forceUpdate();
        this.$showAxiosException(e);
      });
    },
    async changeMultipleAccountingMonth(appointments, value) {
      let reload = false;
      return this.$execWithLoading(async () => {
        const month = `${value.getFullYear()}-${paddingToN(value.getMonth() + 1, 2)}`;
        await setAccountingMonth(appointments.map((a) => ({ id: a.id })), month);
        appointments.forEach((appointment) => {
        // eslint-disable-next-line no-param-reassign
          appointment.monthStr = month;
          // eslint-disable-next-line no-param-reassign
          appointment.month = undefined;
          if (appointment.payslip) {
            // eslint-disable-next-line no-param-reassign
            appointment.payslip.status = 'not_notified';
          } else {
            reload = true;
          }
        });
        this.$forceUpdate();
        if (reload) {
          this.$emit('refresh');
        }
      }, (e) => {
        this.$showAxiosException(e);
      });
    },
    getCheckedList() {
      return this.reserveList.filter((r) => r.checked);
    },
  },
  mounted() {
    this.setupList();
    this.$on('select-all', () => {
      this.reserveList = this.reserveList.map((r) => ({
        ...r,
        checked: r.confirm_status === 'confirmed',
      }));
    });
    this.$on('set-month', (data) => {
      this.changeMultipleAccountingMonth(this.getCheckedList(), data);
    });
  },
};
</script>

<style lang="scss" scoped>
.confirm-status {
    &.confirmed {
      color: #529B2E;
    }
    &.wrong_amount {
      color: #C45656;
    }
    &.not_notified {
      color: #C45656;
    }
}
</style>

<style lang="scss">
.accounting-month-cell {
  .minimize-picker {
    width: 35px;
    max-width: 35px;
    .el-input__inner {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
